<template>
    <div class="banner">
        <div class="container">
            
            <h1>Terms & Conditions</h1>

            <p> 
                These terms and conditions should be carefully read. You agree to the terms indicated here by using 
                the services provided on this website. If you disagree with these terms in any way, please do not 
                use this website or its services. Lynkme360 LLC reserves the right to modify these agreements at any 
                time. Without the express written consent of Lynkme360, no use of Lynkme360’s database or any part of 
                it is permitted. Lynkme360 LLC owns the rights to all of the information on this website.
            </p>

            <p> 
                The website makes no guarantee that the services will not be disrupted, flow without interruptions or 
                mistakes, or be impermeable to unauthorized access to the Lynkme360's server farm, software and hardware 
                failures, or communication lines. Our service is reliant on third-party vendors in part, and we are not 
                liable for any third-party deeds, including any damages, loss, or expense caused to a visitor or a third 
                party as a result of or in connection with the deed. The website is not responsible for the services' 
                availability, as well as the content's shape, reliability, or correctness, as specified in these conditions, 
                and is not liable for infringement of intellectual property, including trademarks and trade secrets.
            </p>


            

            <a data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">Let’s Get Started!</a>
        </div>
    </div>
</template>

<script>
   
    
    export default  {
        name: 'TermsConditions', 
        components: {},
        data() {
            return {

            }
        },
        methods: {
     
        },
        created() {
            
        }
        
    }
</script>