<template>
    <div>
        <div class="blog-dtl">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <div class="vl-parent" style="height: fit-content;">
                            <loading v-model:active="isLoading"
                                :can-cancel="true"
                                :on-cancel="onCancel"
                                :is-full-page="fullPage"
                            />
                        </div>
                        <div class="ttx blog-details-post" v-if="blog_posts">
                            
                            <h3>{{ blog_posts?.title }}</h3>

                            <div class="blog-details-image-thm">
                                <img v-if="blog_posts.article_image" :src="blog_posts.article_image" alt="">
                                <img v-else src="@/assets/images/blog-blank.png" alt="">
                            </div>

                            <div class="upd-date py-2">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-week" viewBox="0 0 16 16">
                                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"></path>
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"></path>
                                </svg> {{ dateTime(blog_posts?.date)}} 
                            </div>

                            <div v-html="blog_posts?.description"></div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <NewsLetter/>
                    </div>
                </div>
            </div>
        </div>
       

        <EmailMarketing/>
    </div>
</template>


<script>
    import EmailMarketing from '../components/homepage/EmailMarketing.vue'
    import moment from "moment";
    import NewsLetter from '@/components/NewsLetter.vue';
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/css/index.css';
    
    export default  {
        name: 'App', 
        components: {
            EmailMarketing,
            NewsLetter,
            Loading,
        },
        data() {
            return {
                blog_posts: null,
                isLoading: false,
                fullPage: false
            }
        },
       
        methods: {
            getPostData() {
                this.isLoading = true;                
                const api = `get_blog_details_by_slug?slug=${this.$route.params.slug}`;
                this.$wpAxios.get(api)
                    .then((res) => {
                        console.log(res.data.blog_data);
                        this.blog_posts = res.data?.blog_data;
                       
                        this.isLoading = false;
                    })
                    .catch((err) => {
                        console.error("Error fetching posts:", err);
                        this.isLoading = false;
                    });
            },
            dateTime(value) {
                return moment(value).format('LL');
            },
        },
        mounted(){

        },
        created() {
            this.isLoading = true;
            this.getPostData();
        }
    }
</script>