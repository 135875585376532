<template>
    <div>
        <div class="banner or_banner">
            <div class="container">
                <h1>Welcome <span>Onboard </span></h1>
                <p>Experience Remarkable Email Marketing Solutions to Get Amazing Results</p>
                <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">Let’s Connect</a>
            </div>
        </div>
        <div class="container content">
            <div class="btm_part or_btm_part">
                <div class="left_part">
                    <h3>Transforming Your <br> Business Ideas Into <br> <span> Picture-Perfect Reality </span></h3>
                    <p>We focus on accomplishing your requirements. Team of pragmatism and passion has been able to uplift standards of client’s business through cutting-edge technology, creative designs and digital marketing tactics. At LynkMe 360, we try our best to cater digital solutions that drive ROI.</p>
                
                <div class="marketing-progress">
                    <div class="circle-bar">
                        <div class="item">
                            <CircleProgress
                                :percent="85"
                                :viewport="true"
                                :show-percent="true"
                                :is-gradient="true"
                            />
                            <h4 class="prgs-title">Business <br> Consulting</h4>
                        </div>
                        <div class="item">
                            <CircleProgress
                                :percent="83"
                                :viewport="true"
                                :show-percent="true"
                                :is-gradient="true"
                            />
                            <h4 class="prgs-title">Digital <br> Marketing</h4>
                        </div>
                        <div class="item">
                            <CircleProgress
                                :percent="89"
                                :viewport="true"
                                :show-percent="true"
                                :is-gradient="true"
                            />
                            <h4 class="prgs-title">Financial <br> Investment</h4>
                        </div>
                    </div>
                </div>
                
                </div>
                <div class="right_part">
                    <img src="@/assets/images/keti.png" alt="" />
                </div>
            </div>
        </div>
        <div class="services-plate-sect content">
            <div class="container offering text-center">
                <h2>Meet our Team of Experts</h2>
                <h4>
                    Happy to have members who go the extra mile to match up to the expectations and set a 
                     <span>benchmark</span> to <span>stand alone</span>.</h4>
            </div>
            <div class="container">
                <div class="row">
                    <div class="col-lg-4">
                        <div class="content-tx">
                            <img src="@/assets/images/mike-boggus.png" alt="">
                            <div class="txt">
                                <h3>Mike Boggus</h3>
                                <h4>Area Manager of Sales & Business Performance</h4>
                                <p>lyncme360 LLC</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="content-tx">
                            <img src="@/assets/images/david-perez.png" alt="">
                            <div class="txt">
                                <h3>David Perez</h3>
                                <h4>Director of Business Development</h4>
                                <p>lyncme360 LLC</p>
                            </div>
                        </div>
                    </div>
                    <div class="col-lg-4">
                        <div class="content-tx">
                            <img src="@/assets/images/luke-dixon.png" alt="">
                            <div class="txt">
                                <h3>Luke Dixon</h3>
                                <h4>Account Manager</h4>
                                <p>lyncme360 LLC</p>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
        </div>
        <EmailMarketing/>
    </div>
</template>


<script>
import EmailMarketing from '../components/homepage/EmailMarketing.vue'
import { ref, onMounted } from "vue";
import CircleProgress from "vue3-circle-progress";
import "vue3-circle-progress/dist/circle-progress.css";
    export default  {
        name: 'App', 
        components: {
            EmailMarketing,
            CircleProgress: CircleProgress,
        },
        setup() {
            const percent = ref(75);

            onMounted(() => {
            setInterval(() => {
                if (percent.value === 25) {
                percent.value = 75;
                } else {
                percent.value = 25;
                }
            }, 1000);
            });

            return {
            percent,
            };
        },
    }
</script>