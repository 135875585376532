<template>
    <div class="offering aligncenter">
        <h2>Exclusive Offers</h2>
        <h4>Deep Dive to learn more about our <br> Lucrative <span> Landing Pages </span> & <span> Email Templates </span></h4>
        <p>Boost your ROI with Industry-Leading Email Campaigns</p>
        
        <section id="section-tab">

            <ul class="nav nav-tabs" id="myTab" role="tablist">
                <li class="nav-item" role="presentation">
                    <button class="nav-link active" id="home-tab" data-bs-toggle="tab" data-bs-target="#home" type="button" role="tab" aria-controls="home" aria-selected="true">Landing Pages</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="profile-tab" data-bs-toggle="tab" data-bs-target="#profile" type="button" role="tab" aria-controls="profile" aria-selected="false">Email Templates</button>
                </li>
                <li class="nav-item" role="presentation">
                    <button class="nav-link" id="contact-tab" data-bs-toggle="tab" data-bs-target="#contact" type="button" role="tab" aria-controls="contact" aria-selected="false">Combo Package</button>
                </li>
            </ul>

            <div id="tab-div">
                <div class="tab-content tab" id="myTabContent">
                    <div class="tab-pane fade tab_boxes show active" id="home" role="tabpanel" aria-labelledby="home-tab">
                        <swiper :modules="modules" 
                            :breakpoints="{
                                '640': {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                '768': {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                                '1024': {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }"
                            :space-between="30" navigation
                            :pagination="{ clickable: true }" @swiper="onSwiper" @slideChange="onSlideChange">
                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/web/box01.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <!-- <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/web/box05.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now  </router-link>
                                        </div>
                                    </div>
                                </swiper-slide> -->

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/web/box02.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now  </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>
                                <!-- <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/web/box03.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now  </router-link>
                                        </div>
                                    </div>
                                </swiper-slide> -->
                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/web/box04.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now  </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/web/box01.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>
                               
                        </swiper>
                    </div>

                    <div class="tab-pane fade tab_boxes" id="profile" role="tabpanel" aria-labelledby="profile-tab">
                        <swiper :modules="modules" 
                            :breakpoints="{
                                '640': {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                '768': {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                                '1024': {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }"
                            :space-between="30" navigation
                            :pagination="{ clickable: true }" @swiper="onSwiper" @slideChange="onSlideChange"
                        >
                                <!-- <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box1.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide> -->

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box2.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box3.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box4.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box5.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>


                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box6.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box7.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <!-- <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box8.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide> -->

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box2.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>
                                
                        </swiper>
                    </div>

                    <div class="tab-pane fade tab_boxes" id="contact" role="tabpanel" aria-labelledby="contact-tab">
                        <swiper :modules="modules" 
                            :breakpoints="{
                                '640': {
                                    slidesPerView: 1,
                                    spaceBetween: 20,
                                },
                                '768': {
                                    slidesPerView: 4,
                                    spaceBetween: 40,
                                },
                                '1024': {
                                    slidesPerView: 3,
                                    spaceBetween: 50,
                                },
                            }"
                            :space-between="30" navigation
                            :pagination="{ clickable: true }" @swiper="onSwiper" @slideChange="onSlideChange"
                        >
                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box7.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/web/box01.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <!-- <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box1.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide> -->

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/web/box02.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now  </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box2.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <!-- <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/web/box03.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now  </router-link>
                                        </div>
                                    </div>
                                </swiper-slide> -->

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box3.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/web/box04.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now  </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box8.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box7.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/web/box01.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide>

                                <!-- <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/web/box05.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now  </router-link>
                                        </div>
                                    </div>
                                </swiper-slide> -->

                                <!-- <swiper-slide>
                                    <div class="item banner_btm_box">
                                        <img src="@/assets/images/email-template/box1.jpg" alt="">
                                        <div class="btn-group">
                                            <button class="btn-products">View Details</button>
                                            <router-link class="btn-products" to="/contact-us">Buy Now </router-link>
                                        </div>
                                    </div>
                                </swiper-slide> -->
                        </swiper>
                    </div>
                </div>
            </div>
    
        </section>
    
    </div>
    </template>
    
    <script>
    import {
        Navigation, 
        Pagination,
        A11y
    } from 'swiper';
    
    // Import Swiper Vue.js components
    import {
        Swiper,
        SwiperSlide
    } from 'swiper/vue';
    
    // Import Swiper styles
    import 'swiper/css';
    import 'swiper/css/navigation';
    import 'swiper/css/pagination';
    //   import 'swiper/css/scrollbar';
    export default {
        name: 'OfferingSection',
        components: {
            Swiper,
            SwiperSlide,
        },
        setup() {
            const onSwiper = (swiper) => {
                console.log(swiper);
            };
            const onSlideChange = () => {
                console.log('slide change');
            };
            return {
                onSwiper,
                onSlideChange,
                modules: [Navigation, Pagination, A11y],
            };
        },
    };
    </script>
    