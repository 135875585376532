<template>
  <div class="green_table_part">
    <h2 class="aligncenter"><span> Ongoing Email Campaigns </span></h2>
    <h6 class="aligncenter"><span> Top 10 Campaigns </span></h6>

    <div class="table-responsive color-child">
      <table
        class="grn_tbl"
        width="100%"
        style="
          border-spacing: 0px;
          margin: auto;
          font-family: 'Montserrat', sans-serif;
        "
        cellspacing="0"
        cellpadding="0"
        border="0"
      >
        <tbody>
          <tr>
            <td>Campaign Name</td>
            <td>Started On</td>
            <td>Volume Sent</td>
            <td>Opens</td>
            <td>Clicks</td>
            <td>Country</td>
          </tr>
        </tbody>
      </table>
    
    <div
      v-for="(streamer, idx) in state.rankingData"
      :key="streamer.userID"
      :ref="
        (el) => {
          state.streamerEl[streamer.userID] = el;
        }
      "
      class="streamer"
    >
      <div class="ranking" ><span class="ranking-no">{{ idx + 1 }}</span> {{ streamer?.c_name }}</div>
      <div class="c_started" >{{ streamer?.c_started }}</div>
      <div class="c_vol" >{{ streamer?.c_vol }}</div>
      <div class="c_opens" >{{ streamer?.c_opens }}</div>
      <div class="c_clicks" >{{ streamer.showScore }}</div>
      <div class="c_country" >
        <!-- <img :src="flagImage(streamer?.c_flag)" /> -->
        <span><img :src="flagImage(streamer?.c_flag)" alt="" /></span> 
        <span>{{ streamer?.c_country }}</span>
      </div>
      <!-- <div class="display-name">{{ streamer.c_name }}</div>
      <div class="score">{{ streamer.showScore }}</div> -->
    </div>
  </div>

    <h2 class="aligncenter"><span> Ongoing Email Campaigns </span></h2>
    <h6 class="aligncenter"><span> Recently Added Campaigns </span></h6>

    <div class="table-responsive">
      <table
        class="grn_tbl"
        width="100%"
        style="
          border-spacing: 0px;
          margin: auto;
          font-family: 'Montserrat', sans-serif;
        "
        cellspacing="0"
        cellpadding="0"
        border="0"
      >
        <tbody>
          <tr>
            <td>Campaign Name</td>
            <td>Started On</td>
            <td>Volume Sent</td>
            <td>Opens</td>
            <td>Clicks</td>
            <td>Country</td>
          </tr>

          <tr style="background-color: #0089b1;">
            <td>
              <div class="d-flex">
                <span>1</span> 
                  <div class="txt">
                    {{ c_data[0]?.c_name }}
                    <br>
                    <span :class="[getDateTime(c_data[0]?.c_started) ? 'recent_added' : 'recent_added_old' ]">
                      <!-- {{  c_added_date }} -->
                      {{ c_data[0]?.c_date }}
                    </span>
                  </div>
              </div>
            </td>
            <td>{{ c_data[0]?.c_started }}</td>
            <td>{{ c_data[0]?.c_vol }}+</td>
            <td>{{ c_data[0]?.c_opens }}</td>
            <td>{{ c_data[0]?.c_clicks }}</td>
            <td>
              <div class="country-fleg">
                <span>
                  <img :src="flagImage(c_data[0]?.c_flag)" alt="" />
                </span> 
                <span>
                  {{ c_data[0]?.c_country }}
                </span>
              </div>
              
            </td>
          </tr>

          <tr style="background-color: #0089b1;">
            <td>
          
              <div class="d-flex">
                <span>2</span>
                  <div class="txt">
                    {{ c_data[1]?.c_name }}
                    <!-- <span class="recent_added_old">Just Added</span> -->
                    <!-- <span class="recent_added_old">
                      {{  getDateTime(c_data[0]?.c_started) }}
                    </span> -->
                    <br>
                    <span :class="[getDateTime2(c_data[1]?.c_started) ? 'recent_added' : 'recent_added_old' ]">
                      <!-- {{  c_added_date2 }} -->
                      {{ c_data[1]?.c_date }}
                    </span>
                  </div>
              </div>
            </td>
            <td>{{ c_data[1]?.c_started }}</td>
            <td>{{ c_data[1]?.c_vol }}+</td>
            <td>{{ c_data[1]?.c_opens }}</td>
            <td>{{ c_data[1]?.c_clicks }}</td>
            <td>
              <div class="country-fleg">
                <span>
                  <img :src="flagImage(c_data[1]?.c_flag)" alt="" />
                </span> 
                <span>
                  {{ c_data[1]?.c_country }}
                </span>
              </div>
              
            </td>
          </tr>

          <tr style="background-color: #0089b1;">
            <td>

              <div class="d-flex">
                <span>3</span>
                  <div class="txt">
                    {{ c_data[2]?.c_name }}
                    <br>
                    <!-- <span class="recent_added">Just Added</span> -->
                    <span :class="[getDateTime3(c_data[2]?.c_started) ? 'recent_added' : 'recent_added_old' ]">
                      <!-- {{  c_added_date3 }} -->
                      {{ c_data[2]?.c_date }}
                    </span>
                  </div>
              </div>
            </td>
            <td>{{ c_data[2]?.c_started }}</td>
            <td>{{ c_data[2]?.c_vol }}+</td>
            <td>{{ c_data[2]?.c_opens }}</td>
            <td>{{ c_data[2]?.c_clicks }}</td>
            <td>
              <div class="country-fleg">
                <span>
                  <img :src="flagImage(c_data[2]?.c_flag)" alt="" />
                </span> 
                <span> 
                  {{ c_data[2]?.c_country }}
                </span>
              </div>
            </td>
          </tr>


          <tr style="background-color: #0089b1;">
            <td>

              <div class="d-flex">
                <span>4</span>
                  <div class="txt">
                    {{ c_data[3]?.c_name }}
                    <br>
                    <!-- <span class="recent_added">Just Added</span> -->
                    <span :class="[getDateTime3(c_data[3]?.c_started) ? 'recent_added' : 'recent_added_old' ]">
                      <!-- {{  c_added_date3 }} -->
                      {{ c_data[3]?.c_date }}
                    </span>
                  </div>
              </div>
            </td>
            <td>{{ c_data[3]?.c_started }}</td>
            <td>{{ c_data[3]?.c_vol }}+</td>
            <td>{{ c_data[3]?.c_opens }}</td>
            <td>{{ c_data[3]?.c_clicks }}</td>
            <td>
              <div class="country-fleg">
                <span><img :src="flagImage(c_data[3]?.c_flag)" alt="" /></span>
                <span> {{ c_data[3]?.c_country }}</span>
              </div>
            </td>
          </tr>

        </tbody>
      </table>
    </div>
  </div>
</template>
<style>
/* tr {
  transition: transform 0.5s ease-in-out;
} */
</style>
<script>

import {  reactive, onBeforeUpdate } from "vue";
import { getRankingJsonFile, changeRandomScore, bubbleSort, swapElement } from "../../helper";

import moment from 'moment';

// Score refresh duration
const refreshTime = 90000;
const animationTime = 1000;

export default  {
  name: "EmailCampaign",
  props: {
    msg: String,
  },
  data() {
    return {
      //c_data: null,
      c_added_date: null,
      c_added_date2: null,
      c_added_date3: null,
      c_data: [
        {
          c_name: "Shrimpy",
          c_started: "August 18,2022",
          c_vol: "200,000",
          c_opens: "21,897(10.94%)",
          c_clicks: 1892,
          c_flag: "flag1.png",
          c_country: "United States",
          c_date: "A Month Ago",
          rank: 1,
          c_bg: "#0089b1",
        },
        {
          c_name: "Independent Reserve",
          c_started: "May 18,2022",
          c_vol: "100,000",
          c_opens: "10,216(10.21%)",
          c_clicks: 1453,
          c_flag: "flag6.png",
          c_country: "Australia",
          c_date: "Two Months Ago",
          rank: 2,
          c_bg: "#308900",
        },
        {
          c_name: "Izovoo",
          c_started: "June 02,2022",
          c_vol: "200,000",
          c_opens: "35,490(17.74%)",
          c_clicks: 8648,
          c_flag: "flag3.png",
          c_country: "United Kingdom",
          c_date: "A Month Ago",
          rank: 3,
          c_bg: "#0089b1",
        },
        {
          c_name: "Lendela",
          c_started: "August 01,2022",
          c_vol: "100,000",
          c_opens: "12,721(12.72%)",
          c_clicks: 1167,
          c_flag: "singapore.png",
          c_country: "Singapore",
          c_date: "Three Months Ago",
          rank: 4,
          c_bg: "#308900",
        },
        {
          c_name: "Knightslots",
          c_started: "January 16,2023",
          c_vol: "200,000",
          c_opens: "23,041(11.52%)",
          c_clicks: 2352,
          c_flag: "flag5.png",
          c_country: "Canada",
          rank: 5,
          c_bg: "#0089b1",
        },
        {
          c_name: "TMGM",
          c_started: "November 01,2022",
          c_vol: "208,179",
          c_opens: "22,878(10.98%)",
          c_clicks: 2516,
          c_flag: "flag6.png",
          c_country: "Thailand",
          rank: 6,
          c_bg: "#308900",
        },
        {
          c_name: "Xtrade",
          c_started: "December 13,2022",
          c_vol: "257,478",
          c_opens: "28,357(11.01%)",
          c_clicks: 3119,
          c_flag: "flag7.png",
          c_country: "Singapore",
          rank: 7,
          c_bg: "#0089b1",
        },
        {
          c_name: "Bons",
          c_started: "October 20,2022",
          c_vol: "100,000",
          c_opens: "9570(9.57%)",
          c_clicks: 1188,
          c_flag: "Japan.png",
          c_country: "Japan",
          rank: 8,
          c_bg: "#308900",
        },
        {
          c_name: "Powerplay",
          c_started: "June 09,2022",
          c_vol: "100,000",
          c_opens: "9,325(9.32%)",
          c_clicks: 1119,
          c_flag: "flag8.png",
          c_country: "Canada",
          rank: 9,
          c_bg: "#0089b1",
        },
        {
          c_name: "Surveyjunkie",
          c_started: "June 20,2022",
          c_vol: "100,000",
          c_opens: "11,974(11.97%)",
          c_clicks: 987,
          c_flag: "flag8.png",
          c_country: "Umited States",
          rank: 10,
          c_bg: "#308900",
        },
      ],
      /* cRankOrder: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      tableData: [
        { name: "Item A", rank: 1 },
        { name: "Item B", rank: 2 },
        { name: "Item C", rank: 3 },
        { name: "Item D", rank: 4 },
      ],
      currentRankOrder: [1, 2, 3, 4], */
    };
  },
  mounted() {
    // setInterval(this.updateRanking, 1000);
    //setInterval(this.updateRank, 3000);
    console.log(moment().format('LL'));
    console.log(moment(this.c_data[0]?.c_started).format('LL'));
    console.log(moment(this.c_data[0]?.c_started).format('MMMM Do YYYY, h:mm:ss a'));
    console.log(moment(this.c_data[0]?.c_started).startOf('day').fromNow());

    // this.$axios.get('https://jsonplaceholder.typicode.com/todos/1')
    //   .then((res)=>{
    //     console.log(res);
    //   })
    //   .catch(err=> console.log(err));
  },
  computed: {},
  methods: {
    getDateTime(date){
      if(moment(date).format('LL') == moment().format('LL')){
        console.log("getDt - working");
        this.c_added_date = "Just Added";
        return true;
      }else{
        this.c_added_date = moment(date).startOf('day').fromNow();
        return false;
      }
    },
    getDateTime2(date){
      if(moment(date).format('LL') == moment().format('LL')){
        console.log("getDt - working");
        this.c_added_date2 = "Just Added";
        return true;
      }else{
        this.c_added_date2 = moment(date).startOf('day').fromNow();
        return false;
      }
    },
    getDateTime3(date){
      if(moment(date).format('LL') == moment().format('LL')){
        this.c_added_date3 = "Just Added";
        return true;
      }else{
        this.c_added_date3 = moment(date).startOf('day').fromNow();
        return false;
      }
    },
    flagImage(fname) {
      //console.log(fname);
      return require(`@/assets/images/${fname}`);
    },
  },

  setup() {
    let state = reactive({
      rankingData: [],
      streamerEl: []
    });

    onBeforeUpdate(() => {
      // If update rendering, clear all element ref.
      state.streamerEl = [];
    });

    // Get json file and update ranking data from server
    getRankingJsonFile("./testData.json").then(
      (responseData) => {
        responseData.forEach((data) => {
          data.showScore = data.c_clicks;
        });
        state.rankingData = responseData;
      }
    );

    // update score
    setInterval(() => {
      changeRandomScore(state.rankingData, refreshTime).then(() => {
        // bubble sorting after updated score.

        // backup previous element top position
        let prevPosition = [];
        state.rankingData.forEach((streamers) => {
          prevPosition[streamers.userID] = state.streamerEl[
            streamers.userID
          ]?.getBoundingClientRect().top;
        });
        bubbleSort(state.rankingData).then((sortedData) => {
          // swap position after sorted
          sortedData.forEach((streamers) => {
            let newTop = state.streamerEl[streamers.userID]?.getBoundingClientRect().top;
            let prevTop = prevPosition[streamers.userID];
            let diffY = prevTop - newTop;
            if (diffY) {
              // swap position after sorted
              swapElement(state.streamerEl[streamers.userID], diffY, animationTime);
            }
          });
        });
      });
    }, refreshTime);

    return {
      state,
    };
  },
};

</script>
<style scoped>
.streamer {
  font-size: 16px;
  font-family: "Helvetica Neue", Helvetica, Arial, "Microsoft JhengHei", sans-serif;
}

.streamer {
  margin: 20px auto;
  width: 100%;
  min-width: 1100px;
  height: 60px;
  transition: all 0.3s ease 0s;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
}

.streamer .ranking-no {
  /* width: 30px; */
  text-align: center;
}

.streamer .picture {
  width: 40px;
  text-align: center;
}

.streamer .picture img {
  margin-right: 2em;
  height: 30px;
  width: 30px;
  border-radius: 50%;
}

.streamer .display-name {
  /* width: 100px; */
  text-align: left;
}

.streamer .score {
  width: 150px;
  text-align: right;
}
.ranking {
    font-family: 'century_schoolbookregular';
    color: #fff;
    width: 298px;
    border-radius: 40px 0 0 40px;
    padding: 10px 0;
    padding-left: 10px;
    background-color: rgb(48, 137, 0);
    flex-shrink: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}
.txt{
  white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 180px;
}
.ranking-no {
    font-size: 30px;
    font-family: 'century_schoolbookregular';
    color: #fff;
    height: 55px;
    width: 55px;
    background-color: #ffffff4d;
    display: inline-block;
    border-radius: 100%;
    text-align: center;
    margin: 0 17px 0 0;
}
.c_started {
    font-family: 'century_schoolbookregular';
    color: #fff;
    height: 75px;
    padding: 28px 0;
    width: 250px;
    background-color: rgb(48, 137, 0);
}
.c_vol {
    font-family: 'century_schoolbookregular';
    color: #fff;
    height: 75px;
    padding: 28px 0;
    width: 250px;
    background-color: rgb(48, 137, 0);    
}
.c_opens{
    font-family: 'century_schoolbookregular';
    color: #c3ff33;
    font-size: 26px;
    line-height: 55px;
    height: 75px;
    padding: 10px 0;
    width: 250px;
    background-color: rgb(48, 137, 0);
}
.c_clicks{
    font-family: 'century_schoolbookregular';
    color: #fff;
    height: 75px;
    padding: 28px 0;
    width: 150px;
    background-color: rgb(48, 137, 0);
}
.c_country{
    font-family: 'century_schoolbookregular';
    color: #fff;
    height: 75px;
    padding: 15px 0;
    width: 250px;
    background-color: #308900;
    border-radius: 0 40px 40px 0;
    display: flex;
    align-items: center;
    font-size: 15px;
    line-height: 18px;
}
.c_country span + span{
  padding-left: 10px;
}

span.recent_added {
  font-size: 12px !important;
  display: inline-block !important;
  background-color: #ff582b !important;
  border-radius: 20px !important;
  height: 19px !important;
  text-transform: uppercase;
  width: fit-content !important;
  padding: 0 10px;
}
span.recent_added_old {
  font-size: 12px !important;
  display: inline-block !important;
  background-color: #ff582b !important;
  border-radius: 20px !important;
  height: 19px !important;
  text-transform: uppercase;
  width: fit-content !important;
  padding: 0 10px;
}
.top10color0 {
  background-color: #408000;
}
.top10color1 {
  background-color: #408000;
}
.top10color2 {
  background-color: #4d9900;
}
.top10color3 {
  background-color: #4d9900;
}
.top10color4 {
  background-color: #59b300;
}
.top10color5 {
  background-color: #59b300;
}
.top10color6 {
  background-color: #66cc00;
}
.top10color7 {
  background-color: #66cc00;
}
.top10color8 {
  background-color: #73e600;
}
.top10color9 {
  background-color: #73e600;
}

table.grn_tbl tr td{
  width: 250px;
}
table.grn_tbl tbody tr > td:nth-child(4){
  width: 220px;
}
table.grn_tbl tr td:first-child{
  width: 319px;
}
.country-fleg{
  display: flex;
  align-items: center;
}
.country-fleg span + span{
  margin-right: 0;
}

@media (max-width:1380px) {
  .c_opens{
    font-size: 22px;
  }
}
@media (max-width:1380px) {
  .ranking-no{
    width: 45px;
    height: 45px;
    font-size: 22px;
    line-height: 40px;
  }
  .c_started, .c_vol, .c_opens, .c_clicks, .c_country{
    height: 65px;
    padding: 20px 0;
  }
  .c_opens{
    line-height: 20px;
    font-size: 19px;
  }
  .streamer{
    margin: 10px 0;
  }
  .ranking{
    width: 240px;
  }
 
}
</style>
