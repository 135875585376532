<template>
 <HomeBanner/> 
   <div class="content">
    <div class="container">
     <EmailCampaign/>
     <CampaignSlider/>
     <ProjectCompleted/>
     <OfferingSection/>
     <GraphPart/>
     <BoostSales/>
     <TestimonialSlider/>
    </div>
     <EmailMarketing/>
   </div>
</template>

<script>

import HomeBanner from "../components/homepage/HomeBanner.vue";
import EmailCampaign from '@/components/homepage/EmailCampaign.vue';
import CampaignSlider from '../components/homepage/CampaignSlider.vue';
import ProjectCompleted from '../components/homepage/ProjectCompleted.vue'
import OfferingSection from '../components/homepage/OfferingSection.vue'
import GraphPart from '../components/homepage/GraphPart.vue'
import BoostSales from '../components/homepage/BoostSales.vue'
import EmailMarketing from '../components/homepage/EmailMarketing.vue'
import TestimonialSlider from '../components/homepage/TestimonialSlider.vue'


export default {
  name: 'HomePage',
  components: {
    HomeBanner,
    EmailCampaign,
    CampaignSlider,
    ProjectCompleted,
    OfferingSection,
    GraphPart,
    BoostSales,
    TestimonialSlider,
    EmailMarketing,
  },
  mounted(){
      console.log(this.$apiUrl);

      // [
      //   {
      //     "userID": "jjjjjjjjjj",
      //     "c_name": "Surveyjunkie",
      //     "c_started": "June 20,2022",
      //     "c_vol": "100,000",
      //     "c_opens": "11,974(11.97%)",
      //     "c_clicks": 987,
      //     "c_flag": "flag9.png",
      //     "c_country": "United States",
      //     "rank": 10,
      //     "c_bg": "#308900"
      //   }
      // ]
    }
}
</script>

