<template>
  <div class="campaigns">
    <h6 class="text-center mb-3">Campaigns Successfully Accomplished</h6>
    <h3 class="color_orange text-center">Succesfully Completed</h3>
    <swiper
      :modules="modules"
      :slides-per-view="1"
      :space-between="0"
      :pagination="{ clickable: true }"
      
      @swiper="onSwiper"
      @slideChange="onSlideChange"
    >
      <swiper-slide v-for="(item, idx) in c_data" :key="idx">
        <div class="item">
          <table
            class="tbl_new responsive"
            width="100%"
            style="
              border-spacing: 0px;
              margin: auto;
              font-family: 'Montserrat', sans-serif;
            "
            cellspacing="0"
            cellpadding="0"
            border="0"
          >
            <thead>
              <tr>
                <th><div>Campaign Name</div></th>
                <th><div>Volume Sent</div></th>
                <th><div>Opens</div></th>
                <th><div>Clicks</div></th>
                <th><div>Country</div></th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td data-label="Campaign Name"> {{item.c_name}}</td>
                <td data-label="Volume Sent">{{item.c_vol}}+</td>
                <td data-label="Opens">{{item.c_opens}}<span> </span></td>
                <td data-label="Clicks">{{item.c_clicks}} <small>(11%)</small></td>
                <td data-label="Country">
                  <img :src="flagImage(item.c_flag)" alt="" /> {{ item.c_country }}
                </td>
              </tr>
            </tbody>
          </table>

          <div class="round_graphics">
            <img src="@/assets/images/uparrow.png" alt="" />
            <h5>
              <span>18 </span> <br />
              Days
            </h5>
            <div class="btmcorner">
              Started <br />
              {{item.c_started}}
            </div>
            <div class="topcorner">
              Completed <br />
              JUNE 17, 2022
            </div>
          </div>
        </div>
      </swiper-slide>
    </swiper>
    <router-link to="/contact-us" class="details">Click for detail report</router-link>
  </div>
</template>

<script>
import { Autoplay, Pagination, A11y } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/pagination";

export default {
  name: "CampaignSlider",
  components: {
    Swiper,
    SwiperSlide,
  },
  data() {
    return {
      c_data: [
        // {
        //   c_name: "Dafabet",
        //   c_started: "09-20-2022",
        //   c_vol: "400,000",
        //   c_opens: "37591(9.39%)",
        //   c_clicks: 3291,
        //   c_flag: "Malaysia.png",
        //   c_country: "Malaysia",
        //   rank: 1,
        //   c_bg: "#0089b1",
        // },
        {
          c_name: "Seabedee",
          c_started: "11-26-2021",
          c_vol: "100,000",
          c_opens: "8890(8.89%)",
          c_clicks: 984,
          c_flag: "flag9.png",
          c_country: "US",
          rank: 2,
          c_bg: "#308900",
        },
        {
          c_name: "Jack998",
          c_started: "07/28/2022",
          c_vol: "179,000",
          c_opens: "17,590(9.82%)",
          c_clicks: 1063,
          c_flag: "singapore.png",
          c_country: "Singapore",
          rank: 3,
          c_bg: "#0089b1",
        },
        // {
        //   c_name: "Skilling",
        //   c_started: "01/13/2023",
        //   c_vol: "683,227",
        //   c_opens: "87,643(12.82%)",
        //   c_clicks: 9640,
        //   c_flag: "philippine.png",
        //   c_country: "Phillipines",
        //   rank: 3,
        //   c_bg: "#0089b1",
        // },
      ],
    };
  },
  methods:{
    flagImage(fname) {
      return require(`@/assets/images/${fname}`);
    },
  },
  setup() {
    const onSwiper = (swiper) => {
      console.log(swiper);
    };
    const onSlideChange = () => {
      console.log("slide change");
    };
    return {
      onSwiper,
      onSlideChange,
      modules: [Autoplay, Pagination, A11y],
    };
  },
};
</script>

<style>
  table.tbl_new tbody tr td:first-child{
    text-align: left;
  }
</style>
