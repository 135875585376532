<template>
  <div
    class="modal fade contact-modal"
    id="exampleModal"
    tabindex="-1"
    aria-labelledby="exampleModalLabel"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content">
        <button
          type="button"
          class="btn-close"
          data-bs-dismiss="modal"
          aria-label="Close"
          ref="Close"
        ></button>
        <div class="modal-body">
          <div class="send-contact my-0">
            <div class="row align-sh">
              <div class="col-12">
                <div class="lrft-top">
                  <div class="title">
                    <h2>Send Us <span>Message</span></h2>
                  </div>
                  <div class="form-area">
                    <form action="" @submit.prevent="submit_form" >
                      <div class="row gy-3">
                        <div class="col-lg-6">
                          <input
                            type="text"
                            v-model="contact_form.fname"
                            placeholder="First Name *"
                            @input="validateFirstname"
                          />
                          <p class="input-error" v-if="errorsFname">{{errorsFname}}</p>
                        </div>
                        <div class="col-lg-6">
                          <input
                            type="text"
                            v-model="contact_form.lname"
                            placeholder="Last Name *"
                            @input="validateLastname"
                          />
                          <p class="input-error" v-if="errorsLname">{{errorsLname}}</p>
                        </div>
                        <div class="col-lg-6">
                          <input
                            type="tel"
                            v-model="contact_form.phone"
                            placeholder="Phone No *"
                            @input="filterNonNumeric"
                          />
                          <p class="input-error" v-if="errorsPhone">{{errorsPhone}}</p>
                        </div>
                        <div class="col-lg-6">
                          <input
                            type="text"
                            v-model="contact_form.subject"
                            placeholder="Subject *"
                            @input="validateSubject"
                          />
                          <p class="input-error" v-if="errorsSubject">{{errorsSubject}}</p>
                        </div>
                        <div class="col-12">
                          <input
                            type="email"
                            v-model="contact_form.email"
                            placeholder="Email *"
                            @input="HandelEmail"
                          />
                          <p class="input-error" v-if="errorsEmail">{{errorsEmail}}</p>
                        </div>
                        <div class="col-12">
                          <input
                            type="text"
                            v-model="contact_form.website"
                            placeholder="Website *"
                            @input="HandelWebsite"
                          />
                          <p class="input-error" v-if="errorsWebsite">{{errorsWebsite}}</p>
                        </div>
                        <div class="col-12">
                          <textarea
                            v-model="contact_form.message"
                            placeholder="Message"
                          ></textarea>
                        </div>
                        <div class="col-12">
                          <button :disabled="btnLoader" type="submit">
                            <span>Send Message</span>

                            <span v-if="btnLoader" class="btn-loader"></span>
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                  <p style="color: white">{{ mail_success }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalModal",
  data() {
    return {
      contact_form: {
        fname: "",
        lname: "",
        phone: "",
        subject: "",
        email: "",
        website: "",
        message: "",
      },
      mail_success: "",

      btnLoader: false,

      apiError: '',
      errorsFname: '',
      errorsLname: '',
      errorsPhone: '',
      errorsSubject: '',
      errorsWebsite: '',
      errorsEmail: '',
    };
  },
  methods: {

    isValidEmail(val) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(val)) {
        return false;
      } else {
        return true;
      }
    },

    validatePhoneNumber(phoneNumber) {
      const phoneRegex = /^\d{10}$/;

      if (!phoneRegex.test(phoneNumber)) {
        return false;
      } else {
        return true;
      }

      
    },

    validateFirstname() {
      if (!this.contact_form.fname) {
        this.errorsFname = 'First name is required';
      } else if (this.contact_form.fname.trim() === '') {
          this.errorsFname = 'Please enter valid first name';
      } else {
        this.errorsFname = '';
      }
    },

    validateLastname() {
      if (!this.contact_form.lname) {
        this.errorsLname = 'Last name is required';
      } else if (this.contact_form.lname.trim() === '') {
          this.errorsLname = 'Please enter valid last name';
      } else {
        this.errorsLname = '';
      }
    },

    validateSubject() {
      if (!this.contact_form.subject) {
          this.errorsSubject = 'Subject is required';
      } else if (this.contact_form.subject.trim() === '') {
          this.errorsSubject = 'Please enter valid subject';
      } else {
        this.errorsSubject = '';
      }
    },

    filterNonNumeric() {
      if (!this.contact_form.phone) {
          this.errorsPhone = 'Phone number is required';
      } else if(this.contact_form.phone.length > 10 || this.contact_form.phone.length < 10){
          this.errorsPhone = 'Please enter 10 digits';
      } else  {
          this.errorsPhone = '';
      }

      this.contact_form.phone = this.contact_form.phone.replace(/\D/g, '');
    },

    HandelEmail(){
        if (!this.contact_form.email) {
            this.errorsEmail = 'Email is required';
        } else if (!this.isValidEmail(this.contact_form.email)) {
            this.errorsEmail = 'Invalid email format';
        } else {
            this.errorsEmail = '';
        }
    },

    validateWebsite(val) {
      const urlRegex = /^[^\s@][^\s@]+\.[^\s@]+$/;
      if (!urlRegex.test(val)) {
        return false;
      } else {
        return true;
      }

    },


    HandelWebsite(){
      if (!this.contact_form.website) {
        this.errorsWebsite = 'Website is required';
      } else if (!this.validateWebsite(this.contact_form.website)) {
        this.errorsWebsite = 'Invalid website format';
      } else {
        this.errorsWebsite = '';
      }
    },


    validateForm() {
               
      let isValid = true;

      if (!this.contact_form.fname) {
          this.errorsFname = 'First name is required';
          isValid = false;
      } else if (this.contact_form.fname.trim() === '') {
          this.errorsFname = 'Please enter valid first name ';
          isValid = false;
      }

      if (!this.contact_form.lname) {
          this.errorsLname = 'Last name is required';
          isValid = false;
      } else if (this.contact_form.lname.trim() === '') {
          this.errorsLname = 'Please enter valid last name';
          isValid = false;
      }


      if (!this.contact_form.phone) {
          this.errorsPhone = 'Phone number is required';
          isValid = false;
      } else if (!this.validatePhoneNumber(this.contact_form.phone)) {
          this.errorsPhone = 'Please enter valid phone number';
          isValid = false;
      }


      if (!this.contact_form.subject) {
          this.errorsSubject = 'Subject is required';
          isValid = false;
      } else if (this.contact_form.subject.trim() === '') {
          this.errorsSubject = 'Please enter valid subject';
          isValid = false;
      }


      if (!this.contact_form.email) {
          this.errorsEmail = 'Email is required';
          isValid = false;
      } else if (!this.isValidEmail(this.contact_form.email)) {
          this.errorsEmail = 'Invalid email format';
          isValid = false;
      }

      if (!this.contact_form.website) {
          this.errorsWebsite = 'Website is required';
          isValid = false;
      } else if (!this.validateWebsite(this.contact_form.website)) {
          this.errorsWebsite = 'Invalid website format';
          isValid = false;
      }

      return isValid;
    },

    submit_form() {
      if (this.validateForm()) {
        this.btnLoader = true;
        this.$axios
            .post(this.$apiUrl + "mail-contact-form", this.contact_form)
            .then((res) => {
                this.contact_form.fname = "",
                this.contact_form.lname = "",
                this.contact_form.phone = "",
                this.contact_form.subject = "",
                this.contact_form.email = "",
                this.contact_form.website = "",
                this.contact_form.message = "",
                this.mail_success = res.data.message,
                this.$refs.Close.click();
                this.$router.push({ name: 'ThankYou' });
                // console.log(res.data.message);
                this.btnLoader = false;
            })
            .catch((err) => {
              this.btnLoader = false;
              console.log(err)});
        }
      // console.log(this.contact_form);
    },
  },
  created() {
    // this.submit_form();
  },
};
</script>
