<template>
    <div class="witheboxes">
          <div class="white_box">
              <h3 class="color_green">{{ c_home_project_title_one }}</h3>
              <h5>{{ c_home_project_parag_one }}</h5>
          </div>
          <div class="white_box">
              <h3 class="color_orange"> {{ c_home_project_title_two }}</h3>
              <h5>{{ c_home_project_parag_two }}</h5>
          </div>
          <div class="white_box">
              <h3 class="color_gray">{{ c_home_project_title_three }}</h3>
              <h5>{{ c_home_project_parag_three }}</h5>
          </div>
          <div class="white_box">
              <h3 class="color_black">{{ c_home_project_title_four }}</h3>
              <h5>{{ c_home_project_parag_four }}</h5>
          </div>
      </div> 
</template>

<script>
export default {
name: 'ProjectCompleted',
data(){
  return{
      c_home_project_title_one: "25",
      c_home_project_parag_one: "Open Campaign",

      c_home_project_title_two: "154",
      c_home_project_parag_two: "Completed",

      c_home_project_title_three: "17",
      c_home_project_parag_three: "Upcoming Campaign",
      
      c_home_project_title_four: "285",
      c_home_project_parag_four: "Brands",
  }
}
}
</script>