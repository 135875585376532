<template>
    <div>
        
        <div class="banner or_banner">
            <div class="container">
                <h1>Email <span>Marketing</span></h1>
                <p>Necessary Integrations are a Key to Success</p>
                <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">Let’s Connect</a>
            </div>
        </div>

        <div class="container content">
            <div class="btm_part or_btm_part">
                <div class="left_part pe-lg-5">
                    <h3>Industry-Leading Emails <br> to <span> Boost Your ROI</span></h3>
                    <p>Why go for good? When you can have the Best Email Marketing Services! It's our forte to analyze your requirements and come up with exceptional designs of email marketing campaigns that make impactful progress on your project. Grab a deal to deliver influential email marketing campaigns through LynkMe 360 in a stipulated time as we’re experts in cart abandonment, flash sales, restocking, review of product & business, email automation flows & upscale emails. </p>

                </div>
                <div class="right_part">
                    <img src="@/assets/images/Smarter-Outcomes.affbe40d.png" alt="" />
                </div>
            </div>
        </div>
        <div class="service-other">
            <div class="container">
                <div class="head">
                    <h2>Your Requirements, <strong>Our Priorities</strong> </h2>
                </div>
                <div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/Developing-Brand-Loyalty-Through-Email.8bef5b48.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>01. Build Brand through Email Campaigns</h4>
                                <p>Leverage your targeted audiences with amazing email marketing campaigns. Through email campaigns, you’ll be able to segment clients' factors based on demographics.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/What-is-the-importance-of-Email-in-your-company.d05ff2d0.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>02. Get Lucrative Designed Campaigns </h4>
                                <p>Picture-perfect solutions help to draw the attention of clientele, through which your business can stand out from the crowd. We focus on creating cutting edge campaigns, which provide top-notch results in the market.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/We-Specialize-In-Scaling-Up.19b995ce.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>03. High-Quality Delivery</h4>
                                <p>Presenting storytelling and dynamic emails is what is needed to create connections with customers. We emphasize well-versed subject lines to increase customers’ engagement so that your business can expand further in the niche market.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/What-Can-We-Offer.2b3b14f1.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>04. Step ahead with consistent A/B testing</h4>
                                <p>Undoubtedly, it is important to maintain a proper workflow for emails by which customer experience will run smoothly as well. This can be done by thorough task testing to make projects done right without any constraints.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <EmailMarketing/>
    </div>
</template>


<script>
    import EmailMarketing from '../components/homepage/EmailMarketing.vue'
    export default  {
        name: 'App', 
        components: {
            EmailMarketing,
        }
    }
</script>