<template>
    <div>
        
        <div class="banner or_banner">
            <div class="container">
                <h1>Services</h1>
                <p>Deep dive to discover what we’ve to offer our customers</p>
                <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">Let’s Connect</a>
            </div>
        </div>

        <div class="services-card contact-card">
            <div class="head">
                <h2><strong>Services</strong> to Meet Your Needs</h2>
                <p>It’s our forte to set up a business from scratch followed by developing digital marketing strategies to boost your ROI and increase leads, clicks and conversions. From e-commerce to B2B firms, our experts ensure to meet the project’s requirements within the provided time and help in getting successful solutions. </p>
            </div>
            <div class="container">
                <div class="row gy-5">
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="item">
                            <img src="@/assets/images/service1.png" alt="">
                            <h3>Digital Marketing</h3>
                            <p>
                                Get connected with potential audiences through digital channels combined with creativity and innovation.
                            </p>
                            <!-- <a href="#" class="link_up">Discover More </a> -->
                            <RouterLink to="/digital-marketing" class="link_up">Discover More </RouterLink>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="item">
                            <img src="@/assets/images/service2.png" alt="">
                            <h3>Email Marketing </h3>
                            <p>
                                Reach out to your customers through strategic email campaigns within a short time span.
                            </p>
                            <RouterLink to="/email-marketing" class="link_up">Discover More </RouterLink>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="item">
                            <img src="@/assets/images/service3.png" alt="">
                            <h3>SEO</h3>
                            <p>
                                We focus on increasing your website reach through apt technical content & optimized content managed by SEO. 
                            </p>
                            <RouterLink to="/seo" class="link_up">Discover More </RouterLink>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="item">
                            <img src="@/assets/images/service4.png" alt="">
                            <h3>UI/UX Strategy</h3>
                            <p>
                                We help you to engage customers through an impressive UI/UX strategy, which will be equally important in comprehending customer’s requirements. 
                            </p>
                            <RouterLink to="/ui-ux-strategy" class="link_up">Discover More </RouterLink>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="item">
                            <img src="@/assets/images/service5.png" alt="">
                            <h3>Pay-Per-Click</h3>
                            <p>
                                Through pay-per-click, you can test different ads and accordingly go for the right one that helps to gain impactful brand recognition. 
                            </p>
                            <RouterLink to="/pay-per-click" class="link_up">Discover More </RouterLink>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="item">
                            <img src="@/assets/images/service6.png" alt="">
                            <h3>Graphic Design </h3>
                            <p>
                                In order to cultivate positive brand recognition in the market, it is important to use effective graphic designs, which you can get through us!
                            </p>
                            <RouterLink to="/graphic-design" class="link_up">Discover More </RouterLink>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="item">
                            <img src="@/assets/images/service7.png" alt="">
                            <h3>Web Design</h3>
                            <p>
                                By presenting appealing visuals, you can draw the attention of more customers with ease, which will help to drive sales and boost your brand in the market too. 
                            </p>
                            <RouterLink to="/web-design" class="link_up">Discover More </RouterLink>
                        </div>
                    </div>
                    <div class="col-xl-3 col-lg-4 col-md-6 col-sm-6">
                        <div class="item">
                            <img src="@/assets/images/service8.png" alt="">
                            <h3>Web Development </h3>
                            <p>
                                The more user-friendly websites, the more customers would like to stay connected, this can be optimized through proper coding and functionality. 
                            </p>
                            <RouterLink to="/web-development" class="link_up">Discover More </RouterLink>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <!-- <div class="excellent-cpmt">
            <div class="container excellent-cont">
                <div class="row">
                    <div class="col-lg-6 col-md-6">
                        <div class="ttx">
                            <h3>Excellent company!</h3>
                            <p>Team of Experts!! That is what I got from LynkMe 360 and I am overwhelmed with the dedication and commitment, which has helped my business grow and achieve amazing results. Through LynkMe 360, my business has flourished and stood out from the other competitors and this guaranteed my business market sustainability. </p>
                            <h2>Mike Boggus <br> <span>(Florida, USA)</span> </h2>
                        </div>
                    </div>
                    <div class="col-lg-6 col-md-6">
                        <div class="vis">
                            <video controls="" crossorigin="">
                                <source src="@/assets/images/Mike_Boggus_video.mp4" type="video/mp4" size="576">
                            </video>
                        </div>
                    </div>

                </div>
            </div>
        </div> -->

        <EmailMarketing/>
    </div>
</template>


<script>
    import EmailMarketing from '../components/homepage/EmailMarketing.vue'
    export default  {
        name: 'App', 
        components: {
            EmailMarketing,
        }
    }
</script>