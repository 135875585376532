<template>
    <div>
        
        <div class="banner or_banner">
            <div class="container">
                <h1>Our <span>Blogs</span> </h1>
                <p>Explore Our Blogs To Invoke Your Thought Provoking Ideas On Various Marketing Techniques</p>
                <a href="#" class="banner_btn primary-hover">Let's Work Together</a>
            </div>
        </div>
        <!-- <div class="container content">
            <div class="btm_part or_btm_part">
                <div class="left_part">
                    <div class="upd-date mb-5">
                        <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-week" viewBox="0 0 16 16">
                            <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                            <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                        </svg>
                        September 21, 2022
                    </div>
                    <h3>Think Of WordPress Web Development Agency In St. <span>Petersburg</span> </h3>
                    <p>Web development process should be a natural part of growing your organization rather than an afterthought.  It makes sense to seek help from a reputable web development agency in St. Petersburg like lyncme360 given the variety of advantages it can offer</p>
                    <router-link to="/BlogDetails" class="primary-hover"> Read More </router-link>
                </div>
                <div class="right_part">
                    <img src="@/assets/images/keti.png" alt="" />
                </div>
            </div>
        </div> -->
        <div class="vl-parent" style="height: fit-content; margin-top: 10px;" v-if="isLoading">
            <loading v-model:active="isLoading"
                :can-cancel="true"
                :on-cancel="onCancel"
                :is-full-page="fullPage"
            />
        </div>
        <div class="testi-card" v-if="blog_posts?.blog_data?.length > 0">
            <div class="container">
                <div class="row gy-5 blog-section">
                    <div class="col-sm-6 col-md-6 col-lg-4" v-for="post in blog_posts?.blog_data" :key="post.id">
                        <div class="card-it blog-listing-card">
                            <div class="banner-thm">
                                <img v-if="post.article_image" :src="post.article_image" alt="">
                                <img v-else src="@/assets/images/blog-blank.png" alt="">
                            </div>
                            <div class="upd-date">
                                <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-calendar-week" viewBox="0 0 16 16">
                                    <path d="M11 6.5a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm-5 3a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1zm3 0a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 .5.5v1a.5.5 0 0 1-.5.5h-1a.5.5 0 0 1-.5-.5v-1z"/>
                                    <path d="M3.5 0a.5.5 0 0 1 .5.5V1h8V.5a.5.5 0 0 1 1 0V1h1a2 2 0 0 1 2 2v11a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V3a2 2 0 0 1 2-2h1V.5a.5.5 0 0 1 .5-.5zM1 4v10a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1V4H1z"/>
                                </svg>
                                {{ dateTime(post.date) }}
                            </div>
                            
                            <div class="text-area">
                                <p>
                                    {{ post.title }}
                                </p>
                                <router-link :to="'/blog/'+ post.slug" class="primary-hover"> Read More </router-link>
                            </div>
                        </div>
                    </div>
                    <nav aria-label="..." >
                        <ul class="pagination justify-content-center" v-if="blog_posts?.last_page > 1">
                            <li class="page-item" v-for="(page, idx) in blog_posts?.last_page" :key="idx" :class="[blog_posts?.current_page == idx + 1 ? 'active' : '']">
                                <a class="page-link" aria-disabled="true" @click="getAllPosts(page)" :disabled="page.url == null">{{ idx + 1 }}</a>
                  
                            </li>
                        </ul>
                    </nav>
                    
                </div>
            </div>
        </div>

        <EmailMarketing/>
    </div>
</template>


<script>
    import EmailMarketing from '../components/homepage/EmailMarketing.vue'
    import moment from "moment";
    import Loading from 'vue-loading-overlay';
    import 'vue-loading-overlay/dist/css/index.css';
    
    export default  {
        name: 'App', 
        components: {
            EmailMarketing,
            Loading,
        },
        data() {
            return {
                blog_posts: [],
                page_no: 1,
                isLoading: false,
                fullPage: false
            }
        },
        methods: {
            getAllPosts(page) {
                this.isLoading = true;
                console.log("Fetching posts...");

                if (page == "&laquo; Previous") {
                    this.page_no--;
                } else if (page == "Next &raquo;") {
                    this.page_no++;
                } else {
                    this.page_no = parseInt(page);
                }

                const api = `get_blog_list/${this.page_no}/15`; 

                this.$wpAxios.get(api)
                    .then((res) => {
                        console.log(res.data);
                        this.blog_posts = res.data;
                       
                        this.isLoading = false;
                    })
                    .catch((err) => {
                        console.error("Error fetching posts:", err);
                        this.isLoading = false;
                    });
            },


            
            dateTime(value) {
                return moment(value).format('LL');
            },      
        },
        created() {
            this.getAllPosts(1);
        }
        
    }
</script>
<style scoped>
.page-item.active .page-link {
    background-color: #00b93d;
    border-color: #00b93d;
}
.page-link {
    color: #00b93d;
}
</style>