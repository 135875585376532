<template>
    <div id="pagetop" class="position-fixed" v-show="scY > 100" @click="toTop">
        <div class="d-flex justify-content-center align-items-center rounded-circle tp-srcl">
            <svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" fill="#FFFFFF" class="bi bi-arrow-up" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M8 15a.5.5 0 0 0 .5-.5V2.707l3.146 3.147a.5.5 0 0 0 .708-.708l-4-4a.5.5 0 0 0-.708 0l-4 4a.5.5 0 1 0 .708.708L7.5 2.707V14.5a.5.5 0 0 0 .5.5z"/>
            </svg>
        </div>
    </div>
</template>

<script>
    export default  {
        name: 'App', 
        data() {
            return {
                scTimer: 0,
                scY: 0,
            }
        },
        mounted() {
            window.addEventListener('scroll', this.handleScroll);
        },
        methods: {
            handleScroll: function () {
                if (this.scTimer) return;
                this.scTimer = setTimeout(() => {
                this.scY = window.scrollY;
                clearTimeout(this.scTimer);
                this.scTimer = 0;
                }, 100);
            },
            toTop: function () {
                window.scrollTo({
                top: 0,
                behavior: "smooth"
            });
            },
        },
    }
</script>