<template>
    <div class="map_part aligncenter">
    
        <div class="mdl_map_part">
            <img class="map-lg-world" src="@/assets/images/graph.png" alt="" />
            <div class="graphicon"> {{ c_home_graph_title_one }} <div class="round_icon"><img src="@/assets/images/graph_icon1.png" alt="" /></div>
                <div class="hvr_box">
                    <h3>{{ c_home_graph_head_one }}</h3>
                    <p>{{ c_home_graph_parag_one }}</p>
                </div>
            </div>
            <div class="graphicon">{{ c_home_graph_title_two }}<div class="round_icon"><img src="@/assets/images/graph_icon2.png" alt="" /></div>
                <div class="hvr_box">
                    <h3>{{ c_home_graph_head_two }}</h3>
                    <p>{{ c_home_graph_parag_two }}</p>
                </div>
            </div>
            <div class="graphicon">{{ c_home_graph_title_three }}<div class="round_icon"><img src="@/assets/images/graph_icon3.png" alt="" /></div>
                <div class="hvr_box">
                    <h3>{{ c_home_graph_head_three }}</h3>
                    <p>{{ c_home_graph_parag_three }}</p>
                </div>
            </div>
            <div class="graphicon">{{ c_home_graph_title_four }}<div class="round_icon"><img src="@/assets/images/graph_icon4.png" alt="" /></div>
                <div class="hvr_box">
                    <h3>{{ c_home_graph_head_four }}</h3>
                    <p>{{ c_home_graph_parag_four }}</p>
                </div>
            </div>
            <div class="graphicon">{{ c_home_graph_title_five }}<div class="round_icon"><img src="@/assets/images/graph_icon5.png" alt="" /></div>
                <div class="hvr_box">
                    <h3>{{ c_home_graph_head_five }}</h3>
                    <p>{{ c_home_graph_parag_five }}</p>
                </div>
            </div>
            <div class="graphicon">{{ c_home_graph_title_six }}<div class="round_icon"><img src="@/assets/images/graph_icon6.png" alt="" /></div>
                <div class="hvr_box">
                    <h3>{{ c_home_graph_head_six }}</h3>
                    <p>{{ c_home_graph_parag_six }}</p>
                </div>
            </div>
            <div class="graphicon">{{ c_home_graph_title_seven }}<div class="round_icon"><img src="@/assets/images/graph_icon7.png" alt="" /></div>
                <div class="hvr_box">
                    <h3>{{ c_home_graph_head_seven }}</h3>
                    <p>{{ c_home_graph_parag_seven }}</p>
                </div>
            </div>
            <div class="bggg"></div>
        </div>
    
        <h4> {{ c_home_graph_heading_one }} <br> {{ c_home_graph_heading_two }} <br>  <span>{{ c_home_graph_heading_highlight }}</span></h4>
        <p class="mx-wth">{{ c_home_graph_heading_parag }}</p>
        <ul>
            <li><a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="primary-hover">{{ c_home_graph_heading_cta_two }}</a></li>
            <li><a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="primary-hover">{{ c_home_graph_heading_cta_one }}</a></li>
        </ul>
    
    </div>
    </template>
    
    <script>
    export default {
      name: 'GraphPart',
      data(){
            return{
                c_home_graph_title_one: "Digital Marketing",
                c_home_graph_head_one: "Digital Marketing",
                c_home_graph_parag_one: "tactics will support to increase convertible sites through impactful email marketing. ",
    
                c_home_graph_title_two: "Email Marketing",
                c_home_graph_head_two: "Email Marketing",
                c_home_graph_parag_two: "We can increase engagement of potential audiences by catering Email Marketing templates crafted with personalized and appealing messages.",
    
                c_home_graph_title_three: "UI/UX Strategy",
                c_home_graph_head_three: "UI/UX Strategy",
                c_home_graph_parag_three: "Through UI/UX Strategy, creation of attractive sites appears that draws attention of potential audiences and helps in building brand awareness in the market. ",
    
                c_home_graph_title_four: "SEO",
                c_home_graph_head_four: "SEO",
                c_home_graph_parag_four: "Our tech experts are capable of strategizing SEO through streamline keywords, tools and analysis, which also focuses on eliminating communication barriers for better rank in search engines. ",
    
                c_home_graph_title_five: "Pay-Per-Click",
                c_home_graph_head_five: "Pay-Per-Click",
                c_home_graph_parag_five: "Get PPC advertisements that can turn tables displayed on search engines, where your business can receive organic traffic. ",
    
                c_home_graph_title_six: "Web & Graphic Design",
                c_home_graph_head_six: "Web & Graphic Design",
                c_home_graph_parag_six: "Let’s create a difference through our specialized Graphic Design services in order to assist your business thrive. ",
    
                c_home_graph_title_seven: "Web Development",
                c_home_graph_head_seven: "Web Development",
                c_home_graph_parag_seven: "Our experts come up with end-to-end Web Development solutions to optimize your business and ensure to run it hasslefree. ",
            
                c_home_graph_heading_one: "Email Marketing Strategies to ",
                c_home_graph_heading_two: "Drive Sales Faster!! Grow with",
                c_home_graph_heading_highlight: "LynkMe360 ",
                c_home_graph_heading_parag: "Email Marketing can be an ultimate source to elevate your ROI in business. Get acquainted with impactful email marketing that can create wonders for your business to maintain a sustainable position in the niche market. ",
                c_home_graph_heading_cta_one: "Let’s Get Started",
                c_home_graph_heading_cta_two: "Talk to our Expert",
            }
        }
    }
    </script>