<template>
    <div>
        
        <div class="banner or_banner">
            <div class="container">
                <h1>SEO</h1>
                <p>Get Customized SEO to Drive Revenue for Your Business</p>
                <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">Let’s Connect</a>
            </div>
        </div>

        <div class="container content">
            <div class="btm_part or_btm_part">
                <div class="left_part pe-lg-5">
                    <h3>Outsmart Your Competition with our <span>SEO Audit to Sustain</span> for a Long</h3>
                    <p>Our SEO specialists care for increasing ranks, and traffic that results in sales and customers for clientele’s business. If you’re looking for more traffic and a better position in SERPS, then you’re at a place to get your business done right! Set sail to achieve market sustainability along with customer loyalty with our SEO experts. </p>

                </div>
                <div class="right_part">
                    <img src="@/assets/images/01.2563f440.png" alt="" />
                </div>
            </div>
        </div>
        <div class="service-other">
            <div class="container">
                <div class="head">
                    <h2>We Go the Extra Mile to Create <strong>Engaging Content </strong> </h2>
                </div>
                <div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/02.b0366e06.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>01. SEO enables to measure success with real numbers</h4>
                                <p>
                                    When it comes to digital marketing analytics, you can analyze your business 
                                    performance through Google Analytics and other web analytics tools. Our team 
                                    equally values comprehending and analyzing performance and accordingly inputs 
                                    SEO to create a positive impact on your business. It also enables to measure 
                                    of digital marketing, which becomes possible through SEO specialists’ approaches.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/03.9ec8e60a.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>02. Boosts Credibility through SEO</h4>
                                <p>
                                    The credibility of your business is something that margins a difference from other businesses. 
                                    Our SEO specialists help in maintaining and boosting your businesses' credibility. This eventually 
                                    results in a better rank appearance on the first page of search engines like Google, Bing, and 
                                    others. In order to drive more sales and clicks for your business, our experts build credibility 
                                    based on critical reviews that maximizes the SEO of the company's reviews.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/04.7798ef17.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>03. Opens the door for high-quality traffic</h4>
                                <p>
                                    When it comes to SEO, not only does traffic toward your website increase but also opens 
                                    opportunities to achieve high-quality traffic. If you’re still stuck at traditional 
                                    business or confused about how to enhance your existing business website, then step into 
                                    LynkMe 360 to get better ranks along with high-quality traffic through our SEO experts. 
                                </p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/05.b241bcc7.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>04. Get Promoted Anywhere Anytime</h4>
                                <p>
                                    One of the smoothest qualities of SEO is that it is available 24/7, through which you 
                                    can start with your promotional operations anywhere and anytime in the digitally evolved 
                                    society. This provides a few-notches high result as our SEO specialists have made it 
                                    possible by catering the best and unique strategies to get promoted on different social 
                                    media platforms. 
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <EmailMarketing/>
    </div>
</template>


<script>
    import EmailMarketing from '../components/homepage/EmailMarketing.vue'
    export default  {
        name: 'App', 
        components: {
            EmailMarketing,
        }
    }
</script>