<template>
     <div class="btm_part">
                <div class="left_part">
                <h3>Perks of Implementing <br> Email Marketing Strategies:</h3>
                <div class="total_list">
                    <ul>
                        <li><img src="@/assets/images/icon1.png" alt="">Personalized & Appealing <span> Content </span></li>
                        <li><img src="@/assets/images/icon2.png" alt="">Increases <span> Sales, </span> Clicks & Opens </li>
                        <li><img src="@/assets/images/icon3.png" alt="">Active engagement with <span>potential audience</span></li>
                    </ul>
                    <ul>
                        <li><img src="@/assets/images/icon4.png" alt="">Generates  <span> Traffic  </span></li>
                        <li><img src="@/assets/images/icon5.png" alt="">More Convertible <span> Leads </span> </li>
                        <li><img src="@/assets/images/icon6.png" alt="">Better Customer <span> Relationships </span></li>
                        <li><img src="@/assets/images/icon7.png" alt="">Self-Promotion <span> Platform </span></li>
                    </ul>
                </div>
                </div>
                <div class="right_part"><img src="@/assets/images/btm_graphics.png" alt="" /></div>
            </div>
</template>

<script>
export default {
  name: 'BoostSales',
  
}
</script>