<template>
    <div class="news-ltr">
        <h3>Newsletter Signup</h3>
        <p>Get Hooked! Sign Up to get the latest catch sent to your inbox.</p>
        <form action="" @submit.prevent="submit_form_newletter">
            <input type="email" v-model="email" placeholder="Enter email address">
            <button type="submit">Subscribe</button>
        </form>
    </div>
</template>
<script>
export default {
    name: "NewLetter",
    data() {
        return {
            email:''
        }
    },
    methods: {
        submit_form_newletter() {
            console.log(this.email);
            this.$axios.post('https://media.bigwavedevelopment.com/newsletterapi/', this.email)
                .then((res) => {
                    console.log(res.data);
                })
                .catch(err => console.log(err));
        }
    }
};
</script>
