<template>
    <div class="testimonial_para">
        <h2>Words that Matter!!</h2>
    </div>
    <swiper :modules="modules" :slides-per-view="1" :space-between="20" :scrollbar="{ draggable: true }" @swiper="onSwiper" :loop="true"  :autoplay="autoplay" @slideChange="onSlideChange">
        <swiper-slide v-for="(item, idx) in c_testimonials" :key="idx">
            <div class="testimonial-sec">
                <!-- <div class="testimonial-man"> -->
                    <!-- <img :src="flagImg(item?.testimonial_img)" alt="" /> -->
                    <!-- <img src="@/assets/images/person3.jpg" alt="" /> -->
                    <!-- <img :src="flagImg(item?.testimonial_img)" alt="" /> -->
                <!-- </div> -->
                <div class="testimonial-title">
                    <h2>{{item.testimonial_title}}</h2>
                    <div class="testimonial-paragraph">
                        <p v-html="item.testimonial_paragraph"></p>
                    </div>
                    <p>{{item.testimonial_person}}</p>
                    <h6>{{ item.testimonial_address }}</h6>
                </div>
            </div>
        </swiper-slide>
    </swiper>
    <button data-bs-toggle="modal" data-bs-target="#exampleModal" class="border-0 rounded-pill py-3 text-white modules-mp-btn">Start Your Email Campaign</button>
</template>

<script>
  import { Scrollbar, A11y } from 'swiper';

  // Import Swiper Vue.js components
  import { Swiper, SwiperSlide } from 'swiper/vue';

  import { Autoplay } from 'swiper';
  import 'swiper/css';
  import 'swiper/css/scrollbar';

export default {
    name: 'TestimonialSlider',
    components: {
        Swiper,
        SwiperSlide,
    },
    data(){
        return {
            c_testimonials : [
                {
                    testimonial_img: "mike-boggus.png",
                    testimonial_title: "Digital Powerhouse",
                    testimonial_paragraph: '“When it comes to digital evolution, I can choose LynkMe 360 without any second thought as they’ve created magical effects on my website, which has helped my business to gain more leads followed by clicks, opens and sales. I am thankful to the whole team of LynkMe 360 for their smart work and dedication that turned my business ideas into reality!”',
                    testimonial_person: "Tom Button",
                    testimonial_address: "(Los Angeles, USA)",
                },
                {
                    testimonial_img: "person2.png",
                    testimonial_title: "Excellent Company",
                    testimonial_paragraph: '“I was confused about whom to trust for my online campaign on different social media platforms. But LynkMe 360 has made my work way easier as they presented lucrative sites in different social media platforms, through which I have been able to appeal better to my targeted audience in the competitive market.”',
                    testimonial_person: "Lily M.",
                    testimonial_address: "(Wales , UK)",
                },
                {
                    testimonial_img: "luke-dixon.png",
                    testimonial_title: "Magnificent Solutions",
                    testimonial_paragraph: '“Being a B2C enterprise, I was struggling to understand the buying behavior of consumers and could not decide which strategy to implement. Through the help of LynkMe 360, I have understood the value of email marketing strategy that has guided me to provide appealing and personalized messages to draw the interests of customers.”',
                    testimonial_person: "Bruno T.",
                    testimonial_address: "(Berlin, Germany)",
                },
                {
                    testimonial_img: "jayson-l.png",
                    testimonial_title: "Digitally Evolved",
                    testimonial_paragraph: '“In the world of digitally evolved competition, it has become difficult to survive for long and thus I wanted support from LynkMe 360 and undoubtedly they’re the leaders in managing digital marketing strategies. Due to them, I’ve been able to put forward my email campaign successfully and achieve leads that boosted my business’ ROI.”',
                    testimonial_person: "Zayed Khan",
                    testimonial_address: "(Ajman, UAE )",
                },
                {
                    testimonial_img: "a-dukes.png",
                    testimonial_title: "One-Stop Solution",
                    testimonial_paragraph: '“Innovative strategies of digital marketing are what is needed to sustain the market. I am fortunate to receive support from LynkMe 360 which helped my business to get impactful recognition and be able to stand out from the crowd. In the future as well, I look forward to working with them if needed anytime.”',
                    testimonial_person: "Karen Gottileb",
                    testimonial_address: "(Florida, USA)",
                },
            ]
        }
    },
    methods:{
        flagImg(fname) {
            return require(`@/assets/images/${fname}`);
        },
    },
    setup() {
        const onSwiper = () => {
        };
        // const onSwiper = (swiper) => {
        //     console.log(swiper);
        // };
        const onSlideChange = () => {
            // console.log('slide change');
        };
        return {
            onSwiper,
            onSlideChange,
            modules: [ Scrollbar, A11y, Autoplay ],
            autoplay: {
                delay: 5000,
                disableOnInteraction: false
            },
        };
    },
};
</script>

<style>
    .content .testimonial_para h2{
        text-align: center;
        font-size: 60px;
        line-height: 62px;
        padding: 100px 0 60px;
    }
    .content .testimonial-title h2{
        font-size: 42px;
    }

    @media (max-width:1366) {
        .content .testimonial_para h2{
            font-size: 53px;
            line-height: 72px;
        }
    }

    @media (max-width: 991px){

        .content .testimonial_para h2 {
            font-size: 48px;
            line-height: normal;
        }
    }

    @media (max-width: 768px){
        .content .testimonial_para h2 {
            font-size: 35px;
            padding: 76px 0 18px;
        }
    }
</style>
