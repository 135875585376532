<template>
    <div>
        
        <div class="banner or_banner">
            <div class="container">
                <h1>Digital <span>Marketing</span> </h1>
                <p>Build and Grow with Digital Marketing for Unparalleled Success</p>
                <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">Let’s Connect</a>
            </div>
        </div>

        <div class="container content">
            <div class="btm_part or_btm_part">
                <div class="left_part pe-lg-5">
                    <h3>It's not important what you sell, how you sell matters! Learn Better with <span>Digital Marketing </span></h3>
                    <p>Are you seeking for impressive digital visibility and want to shine in the race of niche marketing. No more worries, you’ve got LynkMe 360 for a better approach that helps to fulfill your business dreams into reality. Through the use of strategic digital marketing campaigns, your business will reach globally. Let’s dig further to learn what we’ve got to offer!</p>

                </div>
                <div class="right_part">
                    <img src="@/assets/images/dotlps.png" alt="" />
                </div>
            </div>
        </div>
        <div class="service-other">
            <div class="container">
                <div class="head">
                    <h2>Rise and Shine with <strong>Sponsored Ads</strong> </h2>
                </div>
                <div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/Group.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>01. Measure Results being Cost-Effective</h4>
                                <p>Through digital marketing campaigns, you’ll be able to measure your business performance, which not only focuses on delivering high-quality results but also can be done under a friendly budget. Through our digital marketing team, your business will be able to rank better that boosts ROI for the long run.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/Group(1).png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>02. No boundary to reach your Customers </h4>
                                <p>Our team of digital marketing makes sure to be tangible and definable for your business as per selected persona and customer’s requirements creates campaigns accordingly. We focus on maintaining a customer journey map that helps us understand the purchasing behavior of customers better, which becomes effective to present goals of the business impactfully.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/Group4f.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>03. Limitless Customization </h4>
                                <p>Before the campaign sets to proceed in the market, our developers ensure to make limitless chances as per the requirement of the business. Thus, your business campaigns will go through tests and will have endless options to make changes before it steps into the niche market.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/Group(1xs).png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>04. More Interaction, Better Customer Relationships</h4>
                                <p>Digital marketing campaigns create ways to interact and engage with customers impressively. Our developers put their best to provide an amazing experience for potential customers through digital marketing campaigns. This not only help to interact with customers but also emphasizes on building better customer relationship, which your business can receive as well by connecting with LynkMe 360. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <EmailMarketing/>
    </div>
</template>


<script>
    import EmailMarketing from '../components/homepage/EmailMarketing.vue'
    export default  {
        name: 'App', 
        components: {
            EmailMarketing,
        }
    }
</script>