<template>
    <div>
        
        <div class="banner or_banner">
            <div class="container">
                <h1>Pay Per  <span>Click</span></h1>
                <p>Set Sail to Earn More Traffic & Appear on top of SERPs </p>
                <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">Let’s Connect</a>
            </div>
        </div>

        <div class="container content">
            <div class="btm_part or_btm_part">
                <div class="left_part pe-lg-5">
                    <h3>Get right organic search results through <span> PPC & CPC campaigns</span> </h3>
                    <p>Streamlined PPC campaigns help to establish impressive visibility on search engines. We feel delighted to see your business achieving leads, more clicks and sales through our search engine optimization team. Starting from Google Ads/ Bing Ads, Display Marketing along Shopping Campaigns, we’re capable of delivering what is sought by you.</p>

                </div>
                <div class="right_part">
                    <img src="@/assets/images/Developing-And-Administering.4b724ebe.png" alt="" />
                </div>
            </div>
        </div>
        <div class="service-other">
            <div class="container">
                <div class="head">
                    <h2>Journey From Ticks to Clicks in <strong>B2B Marketing</strong> </h2>
                </div>
                <div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/Pay-Per-Click-(PPC)-Advertising-on-Google-Ads.8ee0dc28.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>01. Get Quick Entry into Market</h4>
                                <p>Our team of experts can help you obtain numerous business opportunities, which are present through high-level brand exposure. PPC combined with strategic content marketing is able to generate leads from the niche market.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/What-is-the-importance-of-Email-in-your-company.d05ff2d0.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>02. Track & Measure through Google Ads</h4>
                                <p>High-level performance becomes handy through PPC & CPC campaigns that include positive “ticks” referring to time spent on your website, which has the ability to turn them into “clicks”. The way Google Ads contribute towards sales, billboards/ magazines or any other strategy has failed to meet the goals.  </p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/We-Specialize-In-Scaling-Up.19b995ce.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>03. Integrity to work well with other marketing channels</h4>
                                <p>A group of dedicated and talented developers are able to focus on creating PPC that goes well with SEO, which navigates potential customers via keyword search. Google ads play a quintessential role to keep engaging your audience and all these are amazingly delivered by our team.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/What-Can-We-Offer.2b3b14f1.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>04. Plan & Proceed</h4>
                                <p>You’re in control to decide how you want to spend for your PPCs and which marketing channel would be fruitful for your business. It’s our priority to value your requirements and when you see positive results, scale-up can be achieved right at the moment. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <EmailMarketing/>
    </div>
</template>


<script>
    import EmailMarketing from '../components/homepage/EmailMarketing.vue'
    export default  {
        name: 'App', 
        components: {
            EmailMarketing,
        }
    }
</script>