<template>
    <div>
        
        <div class="banner or_banner">
            <div class="container">
                <h1>Web <span>Development</span></h1>
                <p>Get Creativity in Your Websites</p>
                <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">Let’s Connect</a>
            </div>
        </div>
        <div class="container content">
            <div class="btm_part or_btm_part">
                <div class="left_part pe-lg-5">
                    <h3>Most Trusted <span>Web Development</span> Service Provider!</h3>
                    <p>Reach your customers through web development services as we believe in presenting professional and structured websites. Involvement of front-end, back-end, database and network to compile along with debugging and executing a website has been our niche, which helps to stand out from the crowd. We’re confident enough to provide competitive solutions that will boost your business, sales and revenues.</p>
                </div>
                <div class="right_part">
                    <img src="@/assets/images/01web-developement-1234.824b12f8.png" alt="" />
                </div>
            </div>
        </div>
        <div class="service-other">
            <div class="container">
                <div class="head">
                    <h2>Get Done with <strong>Web Development</strong></h2>
                </div>
                <div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/02web-developement-1234.9be9161a.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>01. We help to load your website faster than usual</h4>
                                <p>Page speed plays a vital role in the representation of a website. Our team of developers emphasizes managing the loading speed of the web page followed by inputting all necessary information that your business requires to showcase.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/03web-developement-1234.ff8675c6.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>02. Ease of Navigation</h4>
                                <p>Our focus is to create a simple and flexible website that easily navigates users and makes the user experience better. The easier a website can be made, the probability of customers visiting your website increases. This is what our team’s intent is to make your website easy to scroll and convenient to browse.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/04web-developement-1234.f28772df.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>03. Target More Customers</h4>
                                <p>The presence of a lucrative website always helps to draw the attention of the customers. It's our forte to create websites that empower us to promote better and generate more leads and clients. This becomes effective in managing traffic in the competitive market. </p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/05web-developement-1234.9c61c470.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>04. Scale up Your Business 5X</h4>
                                <p>A good website is able to get attention from targeted audiences, which in turn helps in increasing sales and revenue. We have a team of experts that are able to enhance traffic, leads, clicks and conversions, which automatically help in scaling your business 5X.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <EmailMarketing/>
        <!-- <ToptoBottom /> -->
    </div>
</template>


<script>
    import EmailMarketing from '../components/homepage/EmailMarketing.vue'
    // import ToptoBottom from '../components/ToptoBottom.vue'
    export default  {
        name: 'App', 
        components: {
            EmailMarketing,
            // ToptoBottom,
        }
    }
</script>