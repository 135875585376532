<template>
  <div class="email-marktng">
    <div class="container">
      <h2>Get Started On Your Email Marketing Campaign Today</h2>
      <p class="pt-3">LynkMe360 has all the tools you need to turn prospects into loyal customers <br>and establish a clientele.</p>
      <!-- <div class="sign-up-box"> -->
        <form class="sign-up-box" action="" @submit.prevent="submit_form">
          <input type="email" v-model="email" placeholder="Enter Your Email" @input="HandelEmail" />
          <!-- <input type="submit" value="" > -->
          <button :disabled="btnLoader" type="submit" class="primary-hover">
            <span>Sign Up</span>
            <span v-if="btnLoader" class="btn-loader"></span>
          </button>
        </form>
        <p class="input-error" v-if="errorsEmail">{{errorsEmail}}</p>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmailMarketing',
  

  data() {
    return {
      
      email: "",
      errorsEmail: '',

      mail_success: "",
      btnLoader: false,
      apiError: '',
      
    };
  },
  methods: {

    isValidEmail(val) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(val)) {
        return false;
      } else {
        return true;
      }
    },


    HandelEmail(){
        if (!this.email) {
            this.errorsEmail = 'Email is required';
        } else if (!this.isValidEmail(this.email)) {
            this.errorsEmail = 'Invalid email format';
        } else {
            this.errorsEmail = '';
        }
    },



    validateForm() {
               
      let isValid = true;

      if (!this.email) {
          this.errorsEmail = 'Email is required';
          isValid = false;
      } else if (!this.isValidEmail(this.email)) {
          this.errorsEmail = 'Invalid email format';
          isValid = false;
      }

      return isValid;
    },


    submit_form() {
      

      if (this.validateForm()) {
        this.btnLoader = true;
        // alert('api')
        this.$axios
        .post(this.$apiUrl + "email_marketing_contact_form", {email: this.email})
        .then((res) => {
         
            this.email = "";
            this.mail_success = res.data.message;

            this.$router.push({ name: 'ThankYou' });
            this.btnLoader = false;
        })
        .catch((err) => {
          this.btnLoader = false;
          console.log(err)
        });
        
      }
    },
  },

}
</script>