<template>
   <!-- <section class="custom-social-proof" ref="customsocialproof">
      <div class="custom-notification">
        <div class="custom-notification-container">
          <div class="custom-notification-image-wrapper">
            <img src="https://tidings.today/wp-content/uploads/2018/08/tidings-today-logo-fav.png">
          </div>
          <div class="custom-notification-content-wrapper">
            <p class="custom-notification-content">
              Mr.Nagarajan - Banglore<br>Purchased <b>4</b> Product Name   
              <small>1 hour ago</small>
            </p>
          </div>
        </div>
        <div class="custom-close" ref="customclose"></div>
      </div>
    </section> -->
      <footer>
        <div class="container pt-md-5">
            <div class="footer-nav">
                <ul>
                  <li><router-link to="./">Home </router-link></li>
                  <li><router-link to="/services">Services</router-link></li>
                  <!-- <li><router-link to="/team-page">Our Team</router-link></li> -->
                  <li><router-link to="/contact-us">Contact</router-link></li>
                  <li><router-link to="/terms-conditions">Terms & Conditions</router-link></li>
                  <li><router-link to="/privacy-policy">Privacy Policy</router-link></li>
                  <li><router-link to="/unsubscribe">Unsubscribe</router-link></li>
                </ul>
            </div>
            <!-- <div class="footer-social">
              <ul>
                <li><a href="#"><img src="@/assets/images/facebook.png" alt=""/></a></li> 
                <li><a href="#"><img src="@/assets/images/instagram.png" alt=""/></a></li> 
                 <li><a href="#"><img src="@/assets/images/twitter.png" alt=""/></a></li> 
                <li><a href="#"><img src="@/assets/images/linkdin.png" alt=""/></a></li> 
              </ul>
            </div> -->
            <div class="address-bar">
              <ul class="d-flex justify-content-center align-items-center">
                <li class="d-flex align-items-start">
                  <img src="@/assets/images/adrees-icon1.png" alt=""/>
                  <span>
                    2630 W Broward BLVD
                    Suite 203 - 1713
                    Fort Lauderdale, FL 33312
                    United States
                  </span>
                </li>
                
                <!-- <li><a @click="openTelegram"><img src="@/assets/images/Telegram.png" alt=""/>@lynkme360</a></li> -->
                <li >
                  <a href="tel:+1 727 440 5867">
                    <img src="@/assets/images/call-icon.png" alt=""/>
                    <span>
                      +1 727 440 5867 
                    </span>
                  </a>
                </li>
                <li>
                  <a href="mailto: info@lynkme360.com">
                    <img src="@/assets/images/mail-icon.png" alt=""/> 
                    <span>info@lynkme360.com</span>
                  </a>
                </li>
              </ul>
            </div>
        </div>
    </footer>
    <div v-show="visible" class="sticky-footer fade-out justify-content-center align-items-center">
        <p class="d-flex align-items-baseline">Get 
          <!-- <span class="px-2">50% off</span> your first email campaign with code: YEAREND50  -->
          <span class="px-2">50% off</span> your first email campaign with code: NEWCAMPAIGN50 
          
          <!-- <span class="d-block"><img class="d-block ps-2" src="@/assets/images/star.png" alt=""/></span> -->
        </p>
        <a href="#" class="cmpgn-btn ms-4" @click="myMethod">Start Your Campaign</a>
        <button class="sticky__ftr__btn" @click="visible = false">
            <img src="@/assets/images/cross-ftr.png" alt="">
        </button>
    </div>
    <ToptoBottom />
</template>

<script>
import { useToast } from "vue-toastification";
import NewSalesPopup from "./NewSalesPopup.vue";
import ToptoBottom from "./ToptoBottom.vue";
export default {
  name: 'FooterPart',
  components: {
    ToptoBottom,
  },
  data() {
    return {
      visible: true,
      salesPopup: -1,
      newSales: [
        {
          name: 'Mark',
          location: 'NewYork',
          msg: 'Purchased - Product Name',
          time: '1 hour ago'
        },
        {
          name: 'Peter',
          location: 'Austrelia',
          msg: 'Purchased - Product Name 2',
          time: '30 mins ago'
        },
      ]
    };
  },
  setup() {
    // Get toast interface
    const toast = useToast();
    return { toast }
  },
  mounted() {
      
  },
  methods: {
    openTelegram() {

      // Create the Telegram web link with a pre-filled message
      const telegramLink = `https://t.me/lynkme360`;

      // Open the link in a new tab
      window.open(telegramLink, '_blank');
    },

    myMethod() {
      console.log(this.newSales[this.salesPopup]);
      // Since you returned `toast` from setup(), you can access it now
      this.toast(<NewSalesPopup salesData={this.newSales[this.salesPopup]} />, {
        position: "bottom-left",
        timeout: 5000,
        closeOnClick: true,
        pauseOnFocusLoss: true,
        pauseOnHover: true,
        draggable: true,
        draggablePercent: 0.6,
        showCloseButtonOnHover: false,
        hideProgressBar: true,
        closeButton: "button",
        icon: false,
        rtl: false
      });
      if (this.newSales.length - 1 > this.salesPopup) {
        this.salesPopup++;
      }
    },
  },
  created() {
    this.myMethod();
  }
}
</script>
<style>
  .fade-out {
    transition: opacity 0.5s;
    opacity: 1;
  }
  .fade-out-hidden {
    opacity: 0;
  }
  .Vue-Toastification__toast--default {
    background-color: #e5e6e7 !important;
    color: #313030 !important;
  }
  .Vue-Toastification__toast {
    
    padding: 2px !important;
      min-height: 19px !important;
      max-width: 324px !important;
      min-width: 283px !important;
  }
  .custom-social-proof {
      position: static !important;
  }
  .custom-social-proof .custom-notification .custom-notification-container .custom-notification-image-wrapper img {
      width: 70px !important;
  }
  .custom-social-proof .custom-notification {
      padding-left: 5px !important;
  }
</style>