<template>
  <div class="banner">
     <div class="container">
         <!-- <img src="./images/logo.png" alt="" /> -->
         <h1>{{ c_home_banner_text_one }} <span>{{ c_home_banner_highlight }}</span> {{ c_home_banner_text_two }} </h1>
         <p>{{ c_home_banner_paragraph_one }} <span>{{ c_home_banner_paragraph_highlight }}</span> {{ c_home_banner_paragraph_two }}</p>
         <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">{{ c_home_banner_cta }}</a>
     </div>
 </div>
 
</template>

<script>
export default {
name: 'HomeBanner',
props: {
 msg: String
},
data(){
   return{
       c_home_banner_text_one: "Value",
       c_home_banner_highlight: "Email Marketing Strategies",
       c_home_banner_text_two: "to Increase Sales, Clicks, Opens & Leads",

       c_home_banner_paragraph_one: "Worried about being a start-up to launch your business on an online platform? Or aspiring to make innovative advancements with existing brands in order to expand further in the market? ",
       c_home_banner_paragraph_highlight: "Take a plunge",
       c_home_banner_paragraph_two: "to know why we’re the best in the lot! Want to sell more? Or looking to excel from others in the market? Get ready to transform from a basic business to a famous brand. ",

       
       c_home_banner_cta: "Let’s Get Started!",
     }
}
}
</script>
