<template>
 <PageHeader/>
    <router-view/> 
    <FooterPart/>
</template>

<script>
import PageHeader from './components/PageHeader.vue'
import FooterPart from './components/FooterPart.vue'
export default {
    name: 'App',
    components: {
        PageHeader,
        FooterPart,
    }
}
</script>


