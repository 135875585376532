<template>
    <div>
        
        <div class="banner or_banner">
            <div class="container">
                <h1>UI/UX <span>Design</span></h1>
                <p>Transformation from Complex to Simplified Applications to Establish Brand</p>
                <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">Let’s Connect </a>
            </div>
        </div>

        <div class="container content">
            <div class="btm_part or_btm_part">
                <div class="left_part pe-lg-5">
                    <h3>Standalone from Competitors through State-of-Art <br> <span>UI/UX Designs</span> </h3>
                    <p>Welcome onboard! You’ve got a team, who are strategically creative and creatively strategic to meet your requirements and present you picturesque results. We streamline the process to create wonders for your business, which can be operated hassle-free by potential audiences. </p>
                </div>
                <div class="right_part">
                    <img src="@/assets/images/uiux-with-best-in-class.2fb43979.png" alt="" />
                </div>
            </div>
        </div>
        <div class="service-other">
            <div class="container">
                <div class="head">
                    <h2>A website can either <strong> Make Or Break</strong> </h2>
                </div>
                <div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/uiux-distinguish-between-ui.fcbfad76.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>01. User Research</h4>
                                <p>Active involvement in data-gathering techniques enables research, which helps our developers to come up with astonishing results. This assists in addressing the issues of your project and eventually being able to fulfill the requirements of the project. </p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/uiux-what-is-the-importance-of.2d520eb4.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>02. UI/UX Designing </h4>
                                <p>Visually appealing and attractive is the common feature that makes stunning sites combined with suitable designs and content justifying the intent of the project is what prioritized by our team of designers. We believe in the seamless delivery of high-quality project delivery within the provided timespan and also shed light on intuitive user experience. </p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/uiux-the-role-of-ux-design-in.36b9e5ce.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>03. Usability Testing</h4>
                                <p>Our experts conduct tests based on different use cases, devices, and scenarios that ensure the effective progress of products’ consistency and accessibility. Through the help of usability testing, quality enriches as it gets filtered via various steps.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/uiux-what-can-we-offer.7f2b74d6.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>04. UI Development</h4>
                                <p>Converting designs into templates requires attention and cautiousness, which is a soft skill of our designers. This helps in building visual components more refined in different frameworks that assist in representing cutting edge user experience. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <EmailMarketing/>
    </div>
</template>


<script>
    import EmailMarketing from '../components/homepage/EmailMarketing.vue'
    export default  {
        name: 'App', 
        components: {
            EmailMarketing,
        }
    }
</script>