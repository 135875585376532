<template>
    <div class="banner">
        <div class="container">
            
            <h1>Privacy Policy</h1>

            <p> 
                Lynkme360 LLC uses one or more third party ad serving technology to serve ads at this site and at our clients' sites. 
                In the course of delivering an ad to you, the third-party ad serving party may place or recognize a unique cookie on your 
                browser and use information (not including your name, address, email address or telephone number) about your visits to 
                this and other Web sites in order to provide advertisements about goods and services that may be of interest to you. 
                Lynkme360 LLC and its third-party ad serving party uses "cookies" to enhance the Internet user's experience on the Web.
            </p>

            <p> 
                Neither Lynkme360 LLC nor such third-party ad serving party uses these cookies to personally identify you in any way, 
                and these cookies in no way damage your system or files. Although we believe that cookies help enhance your experience 
                on the Web (by not repeatedly serving the same ads to you and delivering more relevant messages to you), neither 
                Lynkme360 LLC nor its third-party ad serving party needs cookies to deliver ads to you. If you do not want the benefits 
                of cookies, there is a simple procedure that allows you to deny or accept this feature. In order to "opt out" of the 
                benefits of cookies, you may manually delete your cookies by following the instructions contained in the Help section 
                of your browser.
            </p>


            <p> 
                Lynkme360 LLC is committed to protecting the privacy of Internet users. Accordingly, we strictly adhere to all 
                industry guidelines, including those established by the Direct Marketing Association. If you should have any 
                questions or concerns regarding this Privacy Statement, please contact our Customer Services at <span> info@lynkme360.com </span>
                We use Lynkme360 LLC and other third-party advertising companies to serve ads when you visit our Web site.
            </p>


            <p> 
                These companies may use information (not including your name, address, email address or telephone number) about your 
                visits to this and other Web sites in order to provide advertisements on this site and other sites about goods and 
                services that may be of interest to you. In the course of serving advertisements to this site, our third-party 
                advertiser may place or recognize a unique "cookie" on your browser. Lynkme360 LLC is committed to protecting the 
                privacy of Internet users. Accordingly, we strictly adhere to all industry guidelines. We recommend that you 
                periodically review this Privacy Statement, as Lynkme360 LLC may update it from time to time.
            </p>

            <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">Let’s Get Started!</a>
        </div>
    </div>
</template>

<script>
   
    
    export default  {
        name: 'App', 
        components: {},
        data() {
            return {

            }
        },
        methods: {
     
        },
        created() {
            
        }
        
    }
</script>