<template>
    <div class="banner">
        <div class="container">
            
            <h1>Unsubscribe</h1>

            <div class="container pt-5">
                
                <p class="pt-3">
                    We're sorry to see you go! If you no longer wish to receive updates and notifications from us, 
                    you can unsubscribe using the link below. We appreciate your time with us and value your feedback.
                </p>
            
                <form class="sign-up-box" action="" @submit.prevent="submit_form">
                    <input type="email" v-model="email" placeholder="Enter Your Email" @input="HandelEmail" />
                    
                    <button :disabled="btnLoader" type="submit" class="primary-hover">
                        <span>Unsubscribe</span>
                        <span v-if="btnLoader" class="btn-loader"></span>
                    </button>
                </form>
                <p class="input-error text-danger pt-2" v-if="errorsEmail">{{errorsEmail}}</p>
            </div>
            
        </div>
    </div>
</template>

<script>
   
    
    export default  {
        name: 'App', 
        components: {},
        data() {
            return {
                email: "",
                errorsEmail: '',

                mail_success: "",
                btnLoader: false,
                apiError: '',
            }
        },
        methods: {
            isValidEmail(val) {
                const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
                if (!emailRegex.test(val)) {
                    return false;
                } else {
                    return true;
                }
            },


            HandelEmail(){
                if (!this.email) {
                    this.errorsEmail = 'Email is required';
                } else if (!this.isValidEmail(this.email)) {
                    this.errorsEmail = 'Invalid email format';
                } else {
                    this.errorsEmail = '';
                }
            },



            validateForm() {
                    
                let isValid = true;

                if (!this.email) {
                    this.errorsEmail = 'Email is required';
                    isValid = false;
                } else if (!this.isValidEmail(this.email)) {
                    this.errorsEmail = 'Invalid email format';
                    isValid = false;
                }

                return isValid;
            },


            submit_form() {

                if (this.validateForm()) {
                    this.btnLoader = true;
                    // alert('api')
                    this.$axios
                    .post(this.$apiUrl + "email_marketing_unsubscribe_form", {email: this.email})
                    .then((res) => {
                    
                        this.email = "";
                        this.mail_success = res.data.message;

                        this.$router.push({ name: 'ThankYou' });
                        this.btnLoader = false;
                    })
                    .catch((err) => {
                    this.btnLoader = false;
                    console.log(err)
                    });
                    
                }
            },
        },
        created() {
            
        }
        
    }
</script>