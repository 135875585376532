import { createApp } from 'vue';
import App from './App.vue';
import router from './routes';
import '@/assets/css/style.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle';
import axios from 'axios';
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import TawkMessengerVue from '@tawk.to/tawk-messenger-vue-3';

const options = {
    transition: "Vue-Toastification__slideBlurred",
    maxToasts: 20,
    newestOnTop: true
};

// Axios instance with credentials (for Laravel API)
const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    'apiKey': process.env.VUE_APP_API_KEY // Include API key here if needed
  }
});

// Axios instance for WordPress API without API key
const axiosForWordPress = axios.create({
  baseURL: 'https://lynkme360.com/blogadmin/wp-json/wp/v2',
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json'
  }
});

// Create Vue app
const app = createApp(App);
app.config.globalProperties.$axios = axiosInstance;
app.config.globalProperties.$wpAxios = axiosForWordPress;
app.config.globalProperties.$apiUrl = process.env.VUE_APP_APIURL;
app.config.globalProperties.$apiKey = process.env.VUE_APP_API_KEY;

app.use(router);
app.use(Toast, options);
app.use(TawkMessengerVue, {
  propertyId: '6458d9f1ad80445890ebb65f',
  widgetId: '1gvth6mn1'
});
app.mount('#app');
