<template>
    <!-- <div class="container">
        <h1>Hey!</h1>
        <span>Listen!</span>
    </div> -->
     <section class="custom-social-proof">
        <div class="custom-notification">
          <div class="custom-notification-container">
            <div class="custom-notification-image-wrapper">
              <!-- <img src="https://tidings.today/wp-content/uploads/2018/08/tidings-today-logo-fav.png"> -->
              <span><img src="@/assets/images/flag1.png" alt="" /></span>
            </div>
            <div class="custom-notification-content-wrapper">
                <p class="custom-notification-content">
                  {{ salesData.name }} - <b>{{ salesData.location }}</b><br>{{ salesData.msg }}  
                    <small>{{salesData.time}}</small>
                </p>
              <!-- <p class="custom-notification-content">
                Mr.Nagarajan - Banglore<br>Purchased <b>4</b> Product Name   
                <small>1 hour ago</small>
              </p> -->
            </div>
          </div>
          <div class="custom-close"></div>
        </div>
      </section>
</template>
<script>
export default {
    name: "NewSalesPopup",
    props: ['salesData']
};
</script>
