<template>
  <div>
    <div class="banner or_banner">
      <div class="container">
        <h1>Contact Us</h1>
        <p>Stay Connected To Get Popular Marketing Services</p>
        <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">Let's Work Together</a>
      </div>
    </div>

    <div class="send-contact">
      <div class="container">
        <div class="row align-sh">
          <div class="col-lg-6 col-md-6">
            <div class="lrft-top">
              <div class="title">
                <h2>Send Us <span>Message</span></h2>
              </div>
              <div class="form-area">
                <form action="" @submit.prevent="submit_form">
                  <div class="row gy-3">
                    <div class="col-lg-6">
                      <input
                        type="text"
                        v-model="contact_form.fname"
                        placeholder="First Name *"
                        @input="validateFirstname"
                      />

                      <p class="input-error" v-if="errorsFname">{{errorsFname}}</p>
                    </div>
                    <div class="col-lg-6">
                      <input
                        type="text"
                        v-model="contact_form.lname"
                        placeholder="Last Name *"
                        @input="validateLastname"
                      />
                      <p class="input-error" v-if="errorsLname">{{errorsLname}}</p>
                    </div>
                    <div class="col-lg-6">
                      <input
                        type="tel"
                        v-model="contact_form.phone"
                        placeholder="Phone No *"
                        maxlength="14"
                        @input="filterNonNumeric"
                      />
                      <p class="input-error" v-if="errorsPhone">{{errorsPhone}}</p>
                    </div>
                    <div class="col-lg-6">
                      <input
                        type="text"
                        v-model="contact_form.subject"
                        placeholder="Subject *"
                        @input="validateSubject"
                      />
                      <p class="input-error" v-if="errorsSubject">{{errorsSubject}}</p>
                    </div>
                    <div class="col-12">
                      <input
                        type="email"
                        v-model="contact_form.email"
                        placeholder="Email *"
                        @input="HandelEmail"
                      />
                      <p class="input-error" v-if="errorsEmail">{{errorsEmail}}</p>
                    </div>
                    <div class="col-12">
                      <input
                        type="text"
                        v-model="contact_form.website"
                        placeholder="Website *"
                        @input="HandelWebsite"
                      />
                      <p class="input-error" v-if="errorsWebsite">{{errorsWebsite}}</p>
                    </div>
                    <div class="col-12">
                      <textarea
                        v-model="contact_form.message"
                        placeholder="Message"
                      ></textarea>
                      
                    </div>
                    <div class="col-12">
                      <button :disabled="btnLoader" type="submit">
                        <span>Send Message</span>

                        <span v-if="btnLoader" class="btn-loader"></span>
                      </button>
                    </div>
                  </div>
                </form>
              </div>
              <p style="color: white" v-if="mail_success">{{ mail_success }}</p>
            </div>
          </div>
          <div class="col-lg-6 col-md-6">
            <div class="img-bbrt">
              <img src="@/assets/images/rtl-cont.jpg" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="contact-card">
      <div class="container">
        <div class="row gy-5">
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="item">
              <img src="@/assets/images/lt-geo-alt.svg" alt="" />
              <h3>Address</h3>
              <p>
                <a href="#">7901 4th ST N STE 8022 St. Petersburg, FL 33702</a>
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="item">
              <img src="@/assets/images/lt-envelope.svg" alt="" />
              <h3>Email Us</h3>
              <p>
                <a href="#">info@bigwavedevelopment.com</a>
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="item">
              <img src="@/assets/images/lt-telephone.svg" alt="" />
              <h3>Phone</h3>
              <p>
                <a href="#">+1(727) 300-6244</a>
              </p>
            </div>
          </div>
          <div class="col-lg-3 col-md-6 col-sm-6">
            <div class="item">
              <img src="@/assets/images/lt-skype.svg" alt="" />
              <h3>Skype</h3>
              <p>
                <a href="#">lyncme360</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div> -->

    <!-- <div class="contact-map">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3527.8764292904175!2d-82.64274902599264!3d27.84433576664453!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88c2e6a2b7855c1f%3A0x4e3e0f613f7708f7!2s7901%204th%20St%20N%2C%20St.%20Petersburg%2C%20FL%2033702%2C%20USA!5e0!3m2!1sen!2sin!4v1683014913999!5m2!1sen!2sin"
        width="100%"
        height="582"
        style="border: 0"
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      ></iframe>
    </div> -->

    <EmailMarketing />
  </div>
</template>

<script>
import EmailMarketing from "../components/homepage/EmailMarketing.vue";
export default {
  name: "App",
  components: {
    EmailMarketing,
  },
  data() {
    return {
      contact_form: {
        fname: "",
        lname: "",
        phone: "",
        subject: "",
        email: "",
        website: "",
        message: "",
      },
      mail_success: "",

      btnLoader: false,

      apiError: '',
      errorsFname: '',
      errorsLname: '',
      errorsPhone: '',
      errorsSubject: '',
      errorsWebsite: '',
      errorsEmail: '',
    };
  },
  methods: {

    isValidEmail(val) {
      const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
      if (!emailRegex.test(val)) {
        return false;
      } else {
        return true;
      }
    },

    validatePhoneNumber(phoneNumber) {
      const phoneRegex = /^\d{10}$/;

      if (!phoneRegex.test(phoneNumber)) {
        return false;
      } else {
        return true;
      }

      
    },

    validateFirstname() {
      if (!this.contact_form.fname) {
        this.errorsFname = 'First name is required';
      } else if (this.contact_form.fname.trim() === '') {
          this.errorsFname = 'Please enter valid first name';
      } else {
        this.errorsFname = '';
      }
    },

    validateLastname() {
      if (!this.contact_form.lname) {
        this.errorsLname = 'Last name is required';
      } else if (this.contact_form.lname.trim() === '') {
          this.errorsLname = 'Please enter valid last name';
      } else {
        this.errorsLname = '';
      }
    },

    validateSubject() {
      if (!this.contact_form.subject) {
          this.errorsSubject = 'Subject is required';
      } else if (this.contact_form.subject.trim() === '') {
          this.errorsSubject = 'Please enter valid subject';
      } else {
        this.errorsSubject = '';
      }
    },

    filterNonNumeric() {
      if (!this.contact_form.phone) {
          this.errorsPhone = 'Phone number is required';
      } else if(this.contact_form.phone.length > 10 || this.contact_form.phone.length < 10){
          this.errorsPhone = 'Please enter 10 digits';
      } else {
          this.errorsPhone = '';
      }

      this.contact_form.phone = this.contact_form.phone.replace(/\D/g, '');
    },

    HandelEmail(){
        if (!this.contact_form.email) {
            this.errorsEmail = 'Email is required';
        } else if (!this.isValidEmail(this.contact_form.email)) {
            this.errorsEmail = 'Invalid email format';
        } else {
            this.errorsEmail = '';
        }
    },

    validateWebsite(val) {
      const urlRegex = /^[^\s@][^\s@]+\.[^\s@]+$/;
      if (!urlRegex.test(val)) {
        return false;
      } else {
        return true;
      }

    },


    HandelWebsite(){
      if (!this.contact_form.website) {
        this.errorsWebsite = 'Website is required';
      } else if (!this.validateWebsite(this.contact_form.website)) {
        this.errorsWebsite = 'Invalid website format';
      } else {
        this.errorsWebsite = '';
      }
    },


    validateForm() {
               
      let isValid = true;

      if (!this.contact_form.fname) {
          this.errorsFname = 'First name is required';
          isValid = false;
      } else if (this.contact_form.fname.trim() === '') {
          this.errorsFname = 'Please enter valid first name ';
          isValid = false;
      }

      if (!this.contact_form.lname) {
          this.errorsLname = 'Last name is required';
          isValid = false;
      } else if (this.contact_form.lname.trim() === '') {
          this.errorsLname = 'Please enter valid last name';
          isValid = false;
      }


      if (!this.contact_form.phone) {
          this.errorsPhone = 'Phone number is required';
          isValid = false;
      } else if (!this.validatePhoneNumber(this.contact_form.phone)) {
          this.errorsPhone = 'Please enter valid phone number';
          isValid = false;
      }


      if (!this.contact_form.subject) {
          this.errorsSubject = 'Subject is required';
          isValid = false;
      } else if (this.contact_form.subject.trim() === '') {
          this.errorsSubject = 'Please enter valid subject';
          isValid = false;
      }


      if (!this.contact_form.email) {
          this.errorsEmail = 'Email is required';
          isValid = false;
      } else if (!this.isValidEmail(this.contact_form.email)) {
          this.errorsEmail = 'Invalid email format';
          isValid = false;
      }

      if (!this.contact_form.website) {
          this.errorsWebsite = 'Website is required';
          isValid = false;
      } else if (!this.validateWebsite(this.contact_form.website)) {
          this.errorsWebsite = 'Invalid website format';
          isValid = false;
      }

      return isValid;
    },


    submit_form() {
      

      if (this.validateForm()) {
        this.btnLoader = true;
        this.$axios
        .post(this.$apiUrl + "mail-contact-form", this.contact_form)
        .then((res) => {
          (this.contact_form.fname = "");
            (this.contact_form.lname = "");
            (this.contact_form.phone = "");
            (this.contact_form.subject = "");
            (this.contact_form.email = "");
            (this.contact_form.website = "");
            (this.contact_form.message = "");
            (this.mail_success = res.data.message);
            this.$router.push({ name: 'ThankYou' });
            console.log(res.data.message);
            this.btnLoader = false;
        })
        .catch((err) => {
          this.btnLoader = false;
          console.log(err)
        });
        
      }
    },
  },
  created() {
    // this.submit_form();
  },
};
</script>
