<template>
    <div>
        
        <div class="banner or_banner">
            <div class="container">
                <h1>Web <span>Design</span></h1>
                <p>Excellent Website Design Starts Here</p>
                <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">Let’s Connect </a>
            </div>
        </div>

        <div class="container content">
            <div class="btm_part or_btm_part">
                <div class="left_part pe-lg-5">
                    <h3>Less is More!! </h3>
                    <p>Are you seeking an elegant new website or wondering about innovative changes in your existing websites? You’re at the right place to get your things done right with LynkMe 360. Our team is built with professional, creative and innovative members that focus on presenting lucrative sites to win over the market. </p>
                </div>
                <div class="right_part">
                    <img src="@/assets/images/keeping-simple-keeping-straight.bda58677.png" alt="" />
                </div>
            </div>
        </div>
        <div class="service-other">
            <div class="container">
                <div class="head">
                    <h2>Improve & <strong>Innovate</strong> with LynkMe 360</h2>
                </div>
                <div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/webdg-what-would-be-our-role.94617c08.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>01. Future Ready Websites are here to Make a Difference</h4>
                                <p>Our team helps in creating websites with fresh designs by analyzing market trends. Use of advanced technologies and CSS framework helps our designers to come up with aesthetic sense in sites, which can easily be personalized as per the requirements. </p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/webdg-garner-the-best-option.eac1bff4.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>02. Twists of imaginativeness & Unique Designs </h4>
                                <p>Strategy to input imaginations into reality through unique designs helps to elevate the overlooked outlook of your website. Our designers style sites through WordPress, CMS and others to match up to the client’s expectations. Interactive receptive designs are being provided from our end to maintain credibility and uniqueness in the market. </p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/webdg-unleash-your-potential.03d6b7cd.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>03. Personalized Frameworks & Support with Updates </h4>
                                <p>In order to take customer experience to the next level, our designers ensure that websites are able to function properly based on recent updates. LynkMe 360 utilizes industry-leading structures and accordingly incorporates them into your business website to meet the latest market trends. </p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/webdg-sustainable-growth-process.bade9cf6.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>04.  Process Implementation</h4>
                                <p>Implementing a prime segmentation strategy helps to enhance the entire website’s structure frequently. Our expert designers ensure integration cross-functional, which is mandatory so that websites can be represented perfectly among potential audiences.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <EmailMarketing/>
    </div>
</template>


<script>
    import EmailMarketing from '../components/homepage/EmailMarketing.vue'
    export default  {
        name: 'App', 
        components: {
            EmailMarketing,
        }
    }
</script>