<template>
    <div>
        
        <div class="banner or_banner">
            <div class="container">
                <h1>Testimonials </h1>
                <p>Words that Matter!!</p>
                <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">Let’s Connect</a>
            </div>
        </div>

        <div class="container content">
            <div class="btm_part or_btm_part">
                <div class="left_part">
                    <h3>What are <span>Clients Saying?</span> </h3>
                    <p>
                        <span>LynkMe 360</span> 
                        serves exquisite results that are combined with creativity, innovativeness and ability to maintain 
                        industry-based professionalism. Creating personalized and appealing experiences has always been 
                        their priority and have achieved dedicated assistance in every type of urgency. They’re expert in 
                        simplifying complex situations as per requirement.
                    </p>
                </div>
                <div class="right_part">
                    <img src="@/assets/images/keti.png" alt="" />
                </div>
            </div>
        </div>
        
        <div class="testi-card">
            <div class="container">
                <div class="row gy-5">
                    <div class="col-lg-6">
                        <div class="card-it">
                            <!-- <img src="@/assets/images/unset-ffc.png" alt=""> -->
                            <div class="text-area">
                                <!-- <h4>Digital Powerhouse</h4> -->
                                <p>
                                    “For me LynkMe 360 can be the other name of a powerhouse of dedication and talent 
                                    that helps to meet business goals through digital marketing seamlessly. From the 
                                    very beginning, I have received huge support regarding the up-gradation of existing 
                                    business websites. Their effort has supported me to expand further in the market.”
                                </p>
                                <h5>Ross Flex</h5>
                                <h6><small>(Los Angeles, USA)</small></h6>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="card-it">
                            <!-- <img src="@/assets/images/jayson-l.png" alt=""> -->
                            <div class="text-area">
                                <!-- <h4>Excellent Company</h4> -->
                                <p>
                                    “I am impressed with the solutions that have been presented by LynkMe 360 as this has 
                                    increased leads from my website. In other words, LynkMe 360 digital marketing strategies 
                                    has been worth to pay for due to which I have been able to make my business position better.”
                                </p>
                                <h5>Oliver Franfurt</h5>
                                <h6><small>(Bristol, UK)</small></h6>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="card-it">
                            <!-- <img src="@/assets/images/unset-ffc.png" alt=""> -->
                            <div class="text-area">
                                <!-- <h4>Magnificent Solutions</h4> -->
                                <p>
                                    “LynkMe 360 has been a great help for my start-up as I was confuse whom to trust for digital 
                                    marketing but undoubtedly they have understood my queries really well and have provided the 
                                    solution just the way I wanted.”
                                </p>
                                <h5>Dorothy B.</h5>
                                <h6><small>(Hamburg, Germany)</small></h6>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="card-it">
                            <!-- <img src="@/assets/images/faith-ritter.png" alt=""> -->
                            <div class="text-area">
                                <!-- <h4>Digitally Evolved</h4> -->
                                <p>
                                    “When it comes to trying new things, one can never be sure about its quality. I was 
                                    feeling the same while connecting with LynkMe 360 at first but my perception changed 
                                    soon after I received email marketing campaigns.”
                                </p>
                                <h5>Haya G.</h5>
                                <h6><small>(Dubai, UAE)</small></h6>
                            </div>
                        </div>
                    </div>

                    <div class="col-lg-6">
                        <div class="card-it">
                            <!-- <img src="@/assets/images/unset-ffc.png" alt=""> -->
                            <div class="text-area">
                                <!-- <h4>One-Stop Solution</h4> -->
                                <p>
                                    “I am content with LynkMe 360 as they presented solutions not only lucrative but also 
                                    delivered on-time that saved both my time and money. Huge thanks to the dedicated & 
                                    talented team, without which it would be difficult for me to change my traditional 
                                    business into online business.”
                                </p>
                                <h5>Alexander </h5>
                                <h6><small>(Florida, USA)</small></h6>
                            </div>
                        </div>
                    </div>


                    <div class="col-lg-6">
                        <div class="card-it">
                            <!-- <img src="@/assets/images/unset-ffc.png" alt=""> -->
                            <div class="text-area">
                                <!-- <h4>One-Stop Solution</h4> -->
                                <p>
                                    “Thanks to LynkMe 360 for their continuous dedication, through which I have been 
                                    able to excel from other competitors through digital marketing strategies.”
                                </p>
                                <h5>Arther T.</h5>
                                <h6><small>(Belfast, UK)</small></h6>
                            </div>
                        </div>
                    </div>

                    
                </div>
            </div>
        </div>

        <EmailMarketing/>
    </div>
</template>


<script>
    import EmailMarketing from '../components/homepage/EmailMarketing.vue'
    export default  {
        name: 'App', 
        components: {
            EmailMarketing,
        }
    }
</script>