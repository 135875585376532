<template>
    <div>
        
        <div class="banner or_banner">
            <div class="container">
                <h1>Graphic <span>Design</span></h1>
                <p>Be Different in the League of Graphic Design</p>
                <a href="#" data-bs-toggle="modal" data-bs-target="#exampleModal" class="banner_btn primary-hover">Let’s Connect</a>
            </div>
        </div>

        <div class="container content">
            <div class="btm_part or_btm_part">
                <div class="left_part pe-lg-5">
                    <h3>Transforming <span>Ideas</span> to Reality</h3>
                    <p>We shed light on bringing business ideas to life as it's understandable that “a picture is worth a thousand words''. When you can get the Best, then why go for Good? Let’s set sail to present a fine artwork that completes your business and ensures to stand out from the crowd. This is possible through our dedicated, skilled, and creative team of graphic designers.</p>
                </div>
                <div class="right_part">
                    <img src="@/assets/images/gpdg-we-bring-it-to-life.bda58677.png" alt="" />
                </div>
            </div>
        </div>
        <div class="service-other">
            <div class="container">
                <div class="head">
                    <h2>You Imagine, <strong>We Design</strong></h2>
                </div>
                <div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/gpdg-creating-art-that-fuels-game.537c98dc.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>01. Graphic design creating magic</h4>
                                <p>It will become easier for targeted audiences to recognize your business once it becomes an amazing brand through our team of graphic designers. We feel inspired to take over the boring business idea and put it into an exciting and friendlier site that increases customer engagement. </p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/gpdg-acting-as-a-bridge-between.b0f9ef6a.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>02. Designs to appeal to and engage customers</h4>
                                <p>Designs and graphics are like two sides of a coin and are interconnected with each other in forming lucrative and appealing sites for potential audiences. Thus, we prefer to input appealing designs, through which your business will be able to achieve leads and be able to maintain a remarkable position in the competitive marketplace.</p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/gpdg-how-does-it-help-your-company.439e2e0e.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>03. Promotional Designs to build brand awareness</h4>
                                <p>Through premium quality designs, our graphic designers are able to initiate promotional techniques to win over the targeted customers. Following this, promoting on social media platforms puts real effort into building brand awareness in the market. Most importantly, our team excels in presenting intuitive customer based graphics and designs to relate better. </p>
                            </div>
                        </div>
                    </div>
                    <div class="mb-5 row">
                        <div class="col-lg-6">
                            <div class="item-img">
                                <img src="@/assets/images/gpdg-make-our-presence-feel-throughout.fac91ccd.png" alt="">
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="ttx">
                                <h4>04. Illustrations that speak better than words </h4>
                                <p>Brand logos create a first impression, which is why our graphic designers shed light on putting illustrations that are as clear as water in justifying your projects’ motto. Quality is being assured of our team that excels in creating a difference from others. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>




        <EmailMarketing/>
    </div>
</template>


<script>
    import EmailMarketing from '../components/homepage/EmailMarketing.vue'
    export default  {
        name: 'App', 
        components: {
            EmailMarketing,
        }
    }
</script>